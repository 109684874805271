<template>
  <div
    class="hero-wrapper"
    :style="{ backgroundImage: `url(${backgroundUrl})`, paddingInline: paddingInline, height: height, backgroundPosition: backgroundPosition, backgroundSize: backgroundSize }"
  >
    <div
      class="hero-container"
      :style="{ alignSelf: align, gap: gap}"
    >
      <div
        v-if="btnLocation === 'above'"
        class="mb-12"
      >
        <v-btn
          v-if="hasClickProp || btnLink"
          :class="isCentered ? 'font-weight-bold text-h4 align-self-center' : 'font-weight-bold text-h4 align-self-start' "
          :style="{ color: btnTextColor }"
          x-large
          :color="btnColor"
          @click="handleClick"
        >
          {{ btnText }}
        </v-btn>
      </div>
      <div
        class="sora white--text"
        :style="{fontSize: headingFontSize}"
      >
        <slot />
      </div>
      <div v-if="btnLocation === 'below'">
        <v-btn
          v-if="hasClickProp || btnLink"
          :class="isCentered ? 'font-weight-bold text-h4 align-self-center' : 'font-weight-bold text-h4 align-self-start' "
          :style="{ color: btnTextColor }"
          large
          :color="btnColor"
          @click="handleClick"
        >
          {{ btnText }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  import { gtag_report_conversion } from '../../utils/common'

  export default {
    props: {
      size: { type: String, default: 'large' },
      align: { type: String, default: 'flex-end' },
      btnLink: { type: String, default: undefined },
      btnText: { type: String, default: '' },
      btnColor: { type: String, default: '#AAF0C1' },
      btnLocation: { type: String, default: 'below' },
      btnTextColor: { type: String, default: '#292F36' },
      backgroundUrl: { type: String, default: '' },
      backgroundPosition: { type: String, default: 'center' },
      backgroundSize: { type: String, default: 'cover' },
      gap: { type: String, default: '40px' },
      headingFontSize: { type: String, default: '52px' },
      isCentered: { type: Boolean, default: false, required: false },
    },
    computed: {
      isXl () {
        return this.$vuetify.breakpoint.xlOnly
      },
      isTablet () {
        return this.$vuetify.breakpoint.smAndDown
      },
      isMobile () {
        return this.$vuetify.breakpoint.xsOnly
      },
      paddingInline () {
        return this.isTablet ? '5%' : '9%'
      },
      height () {
        return this.isXl ? '1035px' : '600px'
      },
      hasClickProp () {
        return Object.prototype.hasOwnProperty.call(this.$listeners, 'click')
      }
    },
    methods: {
      handleClick () {
        if (this.btnLink === '/quote') { gtag_report_conversion(this.$gtag, process.env.VUE_APP_FORM_START) }
        this.$router.push(this.btnLink)
        if (Object.prototype.hasOwnProperty.call(this.$listeners, 'click')) {
          this.$emit('click')
        }
      }
    }
  }
</script>

<style scoped>
.hero-wrapper {
  position: relative;
  display: flex;
  padding-block: 50px;
  padding-inline: 9%;
  width: 100vw;
  /* background-size: cover; */
  background-repeat: no-repeat;
}

.hero-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.sora {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 800;
  /* font-size: 52px; */
  line-height: 110%;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.25);
  color: #FFFFFF;
}
</style>
