<template>
  <div class="main-container">
    <div v-if="!path">
      <v-overlay
        v-if="loading"
        class="align-center justify-center overlay-css"
      >
        <v-progress-circular
          v-if="loading"
          :size="80"
          color="primary"
          indeterminate
          class="style-loader"
        >
        </v-progress-circular>
      </v-overlay>
    </div>
    <v-col
      v-if="loading && path"
      class="d-flex justify-center"
    >
      <v-overlay class="overlay-css">
        <ellipse-progress
          class="style-loader"
          :loading="loading"
          :size="180"
          :img-src="path"
          thickness="5"
          empty-thickness="3"
          line-mode="out 5"
          legend="false"
          :animation="'rs 700 infinite'"
          font-size="1.5rem"
          color="#00A1B7"
          empty-color-fill="transparent"
          :filename="filename"
        >
        </ellipse-progress>
      </v-overlay>
    </v-col>
    <v-dialog
      v-model="showMessage"
      top
      :timeout="0"
      light
      max-width="500"
      persistent
    >
      <v-card min-height="10vh">
        <v-card-text class="justify-center text-center body primmary--text">
          <v-row>
            <v-col>{{ shouldShowMessage }}</v-col>
          </v-row>
        </v-card-text>
        <v-row no-gutters>
          <v-col>
            <div class="d-flex justify-center">
              <v-btn
                color="primary"
                class="mb-4 mt-4 font-weight-bold"
                @click="clearError"
              >
                {{ $t("close") }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showModal"
      max-width="500px"
      class="modal-style"
      light
      persistent
    >
      <v-card>
        <div class="d-flex align-center justify-center">
          <div>
            <v-img
              class="pointer"
              height="50"
              width="50"
              contain
              src="/img/email-icon.png"
            />
          </div>
          <div>
            <v-card-title
              class="text-left custom-font"
            >
              {{ $t("otpPopUpMessage") }}
            </v-card-title>
            <div
              v-if="!editing"
              class="d-flex"
            >
              <p
                class="text-left ml-6 font-weight-bold truncate"
                :title="getEmailForOtp"
              >
                {{ getEmailForOtp }}
              </p>
              <!--
              <v-icon
                class="style-icon"
                @click="editEmail"
              >
                mdi-pencil
              </v-icon>-->
            </div>
            <div
              v-if="editing"
              class="style-input"
            >
              <validation-observer ref="emailObserver">
                <validation-provider
                  v-slot="{ errors }"
                  name="Email"
                  rules="required|email|disposable"
                >
                  <v-text-field
                    ref="editEmail"
                    v-model="email"
                    :placeholder="'Edit Email'"
                    :error-messages="errors"
                    @blur="finishEditing"
                    @keyup.enter="finishEditing"
                  ></v-text-field>
                </validation-provider>
              </validation-observer>
            </div>
          </div>
        </div>
        <v-card-text class="custom-card-text">
          <validation-observer ref="otpObserver">
            <validation-provider
              v-slot="{ errors }"
              name="OTP"
              rules="required"
            >
              <v-text-field
                v-model="otp"
                maxlength="6"
                label="Enter OTP"
                :error-messages="errors"
              ></v-text-field>
              <p class="text-style">
                {{ $t("otpMessage") }}
                <a
                  href="#"
                  :class="{ 'disabled-anchor': resendClicked >= 3 }"
                  @click.prevent="resendClicked < 3 && resendOtp(email)"
                >
                  {{ $t("resendOtp") }}
                </a>
              </p>
            </validation-provider>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="false"
            @click="verifyOtp"
          >
            {{ $t("verify") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import Vue from 'vue'
  import firebase from 'firebase/app'
  export default {
    props: {
      path: {
        type: String,
        required: false,
      },
      filename: {
        type: String,
        required: false,
      },
    },
    data () {
      return {
        showModal: false,
        otp: '',
        email: this.getEmailForOtp,
        editing: false,
        otpObserver: null,
        emailObserver: null,
        showMessage: false,
        maxTries: 3,
        resendClicked: 0,
        otpVerified: false
      }
    },
    computed: {
      getEmailForOtp () {
        return this.$store.getters.getLoggedInUser ?? this.$store.getters.getVerifyEmail
      },
      IsEmailVerified () {
        return this.$store.getters.getIsEmailVerified
      },
      getSuccessMessage () {
        return this.$store.getters.getValidationSuccess
      },
      isResendDisabled () {
        return this.resendClicked >= 3
      },
      user () {
        return this.$store.getters.user
      },
      isEmailExist () {
        return this.$store.getters.getIsEmailExist
      },
      loading () {
        return this.$store.getters.getShowLoader
      },
      apiError () {
        return this.$store.getters.getApiError
      },
      shouldShowMessage () {
        return this.$store.getters.getValidationOtpError || this.$store.getters.getValidationSuccess
      },
      getSiteLang () {
        return this.$store.getters.getLanguage
      }
    },
    watch: {
      getEmailForOtp (newEmail) {
        this.email = newEmail
      },
      shouldShowMessage (error) {
        if (error?.length > 0) {
          this.showMessage = true
        }
      },
      user (value) {
        if (value?.email) {
          firebase.database().ref('otps')
            .orderByChild('email')
            .startAt(value?.email)
            .endAt(value?.email)
            .on('value', response => {
              console.log({ response: response.val() })
            }, error => {
              console.log({ error })
            })
        }
        this.otpVerified = value.emailVerified || this.IsEmailVerified
        if (value !== null && value !== undefined && !this.otpVerified) {
          this.openOtpModal(value.email)
        } else {
          this.closeOtpModal()
        }
      }
    },
    created () {
      Vue.prototype.$openOtpModal = this.openOtpModal
      Vue.prototype.$closeOtpModal = this.closeOtpModal
      Vue.prototype.$verifyOtp = this.verifyOtp
    },
    destroyed () {
      this.$root.$off('open-otp-modal', this.openOtpModal)
      this.$root.$off('close-otp-modal', this.closeOtpModal)
      this.$root.$off('verify-otp', this.verifyOtp)
    },
    mounted () {
      this.$store.commit('setLanguage', this.$i18n.locale)
      this.otpVerified = this?.user?.emailVerified
    },
    methods: {
      openOtpModal (email) {
        this.showModal = true
        this.$store.dispatch('sendOtp', {
          email: email,
          preferredLang: this.getSiteLang
        })
        if (email) {
          this.$store.commit('setVerifyEmail', email)
        }
        this.$store.commit('setShowLoader', false)
      },
      closeOtpModal () {
        this.showModal = false
        this.otp = ''
      },
      verifyOtp () {
        const data = {
          email: this.email,
          otp: this.otp
        }
        this.$store.dispatch('verifyEmail', data)
      },
      editEmail () {
        this.editing = true
        this.$nextTick(() => {
          this.$refs.editEmail.focus()
        })
      },
      async finishEditing () {
        await this.$store.dispatch('checkEmailExist', this.email, this.$t)
        this.$refs.emailObserver.validate().then((isValid) => {
          if (isValid) {
            this.editing = false
            this.$store.commit('setVerifyEmail', this.email)
          } else {
            // Handle validation errors
          }
        })
      },
      clearError () {
        this.showMessage = false
        this.$store.commit('setValidationOtpError', '')
        this.$store.commit('setValidationSuccess', '')
        console.log('apiError', this.apiError)
        if (this.apiError || this.IsEmailVerified) {
          this.closeOtpModal()
        }
      },
      resendOtp () {
        this.resendClicked++
        if (this.resendClicked >= 3) {
          this.$store.commit('setValidationOtpError', this.$t('resendLimit'))
          setTimeout(() => {
            this.resendClicked = 0
          }, 60000)
        } else {
          this.$store.dispatch('sendOtp', {
            email: this.email,
            preferredLang: this.getSiteLang
          })
          this.$store.commit('setValidationOtpError', this.$t('otpResentMessage'))
        }
      }
    }
  }
</script>

<style scoped>
.text-style {
  font-size:12px;
}
.modal-style{
  background-color: #fff;;
}
.custom-card-text{
  padding-top: 10px!important;
  padding-bottom: 0px !important;
}
.style-icon{
    position: relative;
    top: -12px;
    margin-left: 7px;
    cursor: pointer;
}
.custom-font{
  font-size: 16px!important;
  word-break: break-word;
}
.style-input{
    left: 23px;
    position: relative;
    top: -18px;
    width: 280px;
}
.error-message{
  font-size: 12px;
  color:#ff5252;
}
.disabled-anchor{
  pointer-events: none;
  color: #ccc;
  text-decoration: none;
}
 .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media only screen and (max-width: 600px) {
    .truncate {
      max-width: 220px;
    }
    .style-input {
     max-width: 240px;
    }
  }
  @media only screen and (min-width: 1000px) {
    .truncate {
      max-width: 360px;
    }
  }
.style-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 300;
}
.main-container{
  position: relative;
}
.overlay-css{
  z-index: 501!important;
}
</style>
