
import Vue from 'vue'
import Router from 'vue-router'
import AuthGaurd from './auth-gaurd'
import store from './store/store'

Vue.use(Router)

const clearAfficiencyData = (to, from, next) => {
  store.dispatch('resetAfficiencyData')
  next()
}
export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/pages',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: 'Lock',
          path: 'lock',
          component: () => import('@/views/pages/Lock')
        },
        {
          name: 'Login',
          path: 'login',
          component: () => import('@/views/pages/Login'),
          meta: {
            title: 'Login Page',
            canonical: 'https://www.blanket.com/login',
          },
        },
        {
          name: 'Pricing',
          path: 'pricing',
          component: () => import('@/views/pages/Pricing'),
        },
        {
          name: 'Product',
          path: 'product',
          component: () => import('@/views/pages/Product'),
        },
        {
          name: 'Register',
          path: 'register',
          component: () => import('@/views/pages/Register'),
          meta: {
            title: 'Register Page',
            canonical: 'https://www.blanket.com/register',
          },
        },
        {
          name: 'Reset',
          path: 'reset',
          component: () => import('@/views/pages/Reset'),
          meta: {
            title: 'Reset Page',
            canonical: 'https://www.blanket.com/reset',
          },
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'My Blanket',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
          meta: {
            title: 'Home - Blanket.com',
            canonical: 'https://www.blanket.com/',
          },
        },
        {
          name: 'My Blanket',
          path: '/partner/:partnername/:campaignid?',
          props: { default: true },
          component: () => import('@/views/dashboard/Dashboard'),
        },
        {
          name: 'localization-test',
          path: 'localization-test',
          component: () => import('@/views/dashboard/Dashboard-i18n'),
        },
        // Admin
        {
          name: 'Blanket Term Life Questions',
          path: 'blankettermquestions',
          component: () => import('@/views/dashboard/Admin/BlanketTermQuestions'),
          beforeEnter: AuthGaurd
        },
        {
          name: 'Admin Partners',
          path: 'adminpartners',
          component: () => import('@/views/dashboard/Admin/AdminPartners'),
          beforeEnter: AuthGaurd
        },
        // mrmr camp
        {
          name: 'Campaign Admin',
          path: 'partnercampaigns/:partnerkey',
          props: { default: true },
          component: () => import('@/views/dashboard/Admin/AdminPartnerCampaign'),
        },
        {
          name: 'Admin Anico Term Life Policy',
          path: 'blanketanicopolicyadmin',
          component: () => import('@/views/dashboard/Admin/BlanketAnicoPolicyAdmin'),
          beforeEnter: AuthGaurd
        },
        {
          name: 'Blanket Symetra Application Questions',
          path: 'blanketsymetraquestions',
          component: () => import('@/views/dashboard/Admin/BlanketSymetraQuestions'),
          beforeEnter: AuthGaurd
        },
        {
          name: 'Blanket Anico Application Questions',
          path: 'blanketanicoquestions',
          component: () => import('@/views/dashboard/Admin/BlanketAnicoQuestions'),
          beforeEnter: AuthGaurd
        },
        {
          name: 'Term Question Test',
          path: 'RepeatTest',
          component: () => import('@/views/dashboard/Admin/TermQuestiontest'),
          beforeEnter: AuthGaurd
        },
        // Admin Reports
        {
          name: 'Admin Policies',
          path: 'adminreports/policies',
          component: () => import('@/views/dashboard/AdminReports/AdminPolicies'),
          beforeEnter: AuthGaurd
        },
        {
          name: 'Reports Partners Accounts',
          path: '/ReportsPartnersAccounts',
          component: () => import('@/views/dashboard/AdminReports/ReportsPartnersAccounts'),
          beforeEnter: AuthGaurd
        },
        {
          name: 'Reports Partners Sales',
          path: '/ReportPartnersSales',
          component: () => import('@/views/dashboard/AdminReports/ReportsPartnersSales'),
          beforeEnter: AuthGaurd
        },
        {
          name: 'Reports Partners Traffic',
          path: '/ReportPartnersTraffic',
          component: () => import('@/views/dashboard/AdminReports/ReportsPartnersTraffic'),
          beforeEnter: AuthGaurd
        },
        {
          name: 'Reports Partners Volume',
          path: '/ReportPartnersVolume',
          component: () => import('@/views/dashboard/AdminReports/ReportsPartnersVolume'),
          beforeEnter: AuthGaurd
        },
        {
          name: 'BNC Applicant Spy',
          path: '/BNCSpy',
          component: () => import('@/views/dashboard/AdminReports/BNCSpy'),
          beforeEnter: AuthGaurd
        },
        {
          name: 'Sales By Policy',
          path: 'adminreports/salesbypolicy',
          component: () => import('@/views/dashboard/AdminReports/SalesByPolicy'),
          beforeEnter: AuthGaurd
        },
        {
          name: 'Total Sales',
          path: 'adminreports/totalsales',
          component: () => import('@/views/dashboard/AdminReports/TotalSales'),
          beforeEnter: AuthGaurd
        },
        {
          name: 'BI Profile',
          path: 'adminreports/biprofile',
          component: () => import('@/views/dashboard/AdminReports/BIProfile'),
          beforeEnter: AuthGaurd
        },

        // Company Pages
        {
          name: 'Our Team',
          path: 'about/team',
          component: () => import('@/views/dashboard/Company/OurTeam.vue'),
          meta: {
            title: 'About Team Page',
            canonical: 'https://www.blanket.com/about/team',
          },
        },
        {
          name: 'News',
          path: 'about/news',
          component: () => import('@/views/dashboard/Company/News.vue'),
          meta: {
            title: 'About News Page',
            canonical: 'https://www.blanket.com/about/news',
          },
        },
        // {
        //   name: 'News',
        //   path: 'about/news/details',
        //   component: () => import('@/views/dashboard/Company/NewsDetails.vue'),
        // },
        {
          name: 'News Blanket Acquires BNC Insurance',
          path: 'about/news/details/:page',
          component: () => import('@/views/dashboard/Company/NewsBlogs/blanket-acquires-bnc-insurance.vue'),
        },
        {
          name: 'Partnerships',
          path: 'about/partnerships',
          component: () => import('@/views/dashboard/Company/Partnerships.vue'),
          meta: {
            title: 'About Partnership Page',
            canonical: 'https://www.blanket.com/about/partnerships',
          },
        },
        {
          name: 'Create Partnership',
          path: 'about/ggacreatepartnership',
          component: () => import('@/views/dashboard/Company/GGACreatePartnership.vue'),
          meta: {
            title: 'Create Partnership Page',
            canonical: 'https://www.blanket.com/about/ggacreatepartnership',
          },
        },
        {
          name: 'Create Partnership',
          path: '/travelagent',
          component: () => import('@/views/dashboard/Company/GGACreatePartnership.vue'),
        },
        {
          name: 'How Do I Apply',
          path: 'howtoapply',
          component: () => import('@/views/dashboard/Company/Apply')
        },
        {
          name: 'FAQs',
          path: 'faqs',
          component: () => import('@/views/dashboard/Company/FAQ'),
          meta: {
            title: 'Faq Page',
            canonical: 'https://www.blanket.com/faqs',
          },
        },
        {
          name: 'Contact Us',
          path: 'contactus',
          component: () => import('@/views/dashboard/Company/ContactUs'),
          meta: {
            title: 'Contact Us Page',
            canonical: 'https://www.blanket.com/contactus',
          },
        },
        {
          name: 'Contact Us Canada',
          path: 'contactus-ca',
          component: () => import('@/views/dashboard/Company/ContactUsCA'),
          meta: {
            title: 'Contact US Canada Page',
            canonical: 'https://www.blanket.com/contactus-ca',
          },
        },
        // Auto
        {
          name: 'Auto',
          path: 'auto',
          component: () => import('@/views/dashboard/Auto/Auto')
        },
        {
          name: 'Learn More',
          path: 'auto/learnmore',
          component: () => import('@/views/dashboard/Auto/LearnMore')
        },
        {
          name: 'Auto',
          path: 'auto/info',
          component: () => import('@/views/dashboard/Auto/AutoInfo')
        },
        {
          name: 'Auto',
          path: 'auto/selectplan',
          component: () => import('@/views/dashboard/Auto/AutoSelectPlan')
        },
        {
          name: 'Auto Pay',
          path: 'auto/pay',
          component: () => import('@/views/dashboard/Auto/AutoPay')
        },
        // Homeowners
        {
          name: 'Home Insurance',
          path: 'homeowners',
          component: () => import('@/views/dashboard/Home/HomeInsurance')
        },
        {
          name: 'Home Info',
          path: 'homeowners/info',
          component: () => import('@/views/dashboard/Home/HomeInfo')
        },
        {
          name: 'Learn More',
          path: 'homeowners/learnmore',
          component: () => import('@/views/dashboard/Home/LearnMore')
        },
        {
          name: 'Home Select Plan',
          path: 'homeowners/selectplan',
          component: () => import('@/views/dashboard/Home/HomeSelectPlan')
        },
        {
          name: 'Home Pay',
          path: 'homeowners/pay',
          component: () => import('@/views/dashboard/Home/HomePay')
        },
        {
          name: 'Residence Information',
          path: 'homeowners/residenceinfo',
          component: () => import('@/views/dashboard/Home/HomeResidenceInfo')
        },
        {
          name: 'Coverages',
          path: 'homeowners/coverages',
          component: () => import('@/views/dashboard/Home/HomeCoverage')
        },
        // Pet
        // {
        //   name: 'Pet',
        //   path: 'pet',
        //   component: () => import('@/views/dashboard/Pet/Pet'),
        // },
        // {
        //   name: 'Pet',
        //   path: 'pet/partner/:partnername/:campaignid',
        //   props: { default: true },
        //   component: () => import('@/views/dashboard/Pet/Pet'),
        // },
        // {
        //   name: 'Pet CA',
        //   path: 'pet-ca',
        //   component: () => import('@/views/dashboard/Pet/PetCA'),
        // },
        // {
        //   name: 'Pet Info',
        //   path: 'pet/info',
        //   component: () => import('@/views/dashboard/Pet/PetInfo'),
        // },
        // {
        //   name: 'Pet Info',
        //   path: 'pet/infoca',
        //   component: () => import('@/views/dashboard/Pet/PetInfoCa'),
        // },
        // {
        //   name: 'Pet Select Plan',
        //   path: 'pet/selectplan',
        //   component: () => import('@/views/dashboard/Pet/PetSelectPlan'),
        // },
        // {
        //   name: 'Pet Pay',
        //   path: 'pet/pay',
        //   component: () => import('@/views/dashboard/Pet/PetPay'),

        // },
        // {
        //   name: 'Pet Policy',
        //   path: 'pet/policy',
        //   component: () => import('@/views/dashboard/Pet/PetPolicy'),
        // },
        {
          name: 'Learn More',
          path: 'pet/learnmore',
          component: () => import('@/views/dashboard/Pet/LearnMore')
        },
        // Renters
        {
          name: 'Renters',
          path: 'renters',
          component: () => import('@/views/dashboard/Renters/Renters')
        },
        {
          name: 'Renters Info',
          path: 'renters/info',
          component: () => import('@/views/dashboard/Renters/RentalInfo')
        },
        {
          name: 'Renters Select Plan',
          path: 'renters/selectplan',
          component: () => import('@/views/dashboard/Renters/RentersSelectPlan')
        },
        {
          name: 'Property Info',
          path: 'renters/propertyinfo',
          component: () => import('@/views/dashboard/Renters/PropertyInfo')
        },
        {
          name: 'Renters Pay',
          path: 'renters/pay',
          component: () => import('@/views/dashboard/Renters/RentersPay')
        },
        {
          name: 'Renters Coverages',
          path: 'renters/coverages',
          component: () => import('@/views/dashboard/Renters/Coverages')
        },
        {
          name: 'Renters Policy',
          path: 'renters/policy',
          component: () => import('@/views/dashboard/Renters/RentersPolicy')
        },
        // Renters CA
        {
          name: 'Renters Ca',
          path: 'renters-ca',
          component: () => import('@/views/dashboard/RentersCA/RentersCa'),
          meta: {
            title: 'Renter Ca Page',
            canonical: 'https://www.blanket.com/renters-ca',
          },
        },
        {
          name: 'Renters Ca',
          path: 'renters/infoca',
          component: () => import('@/views/dashboard/RentersCA/RentalInfoCa')
        },
        // Landlord
        {
          name: 'Landlord',
          path: 'landlord',
          component: () => import('@/views/dashboard/Landlord/Landlord')
        },
        {
          name: 'Learn More',
          path: 'landlord/learnmore',
          component: () => import('@/views/dashboard/Landlord/LearnMore')
        },
        // Business
        {
          name: 'Business',
          path: 'business',
          component: () => import('@/views/dashboard/Business/Business')
        },
        {
          name: 'Learn More',
          path: 'business/learnmore',
          component: () => import('@/views/dashboard/Business/LearnMore')
        },
        // Condo
        {
          name: 'Condo',
          path: 'condo',
          component: () => import('@/views/dashboard/Condo/Condo')
        },
        {
          name: 'Condo Info',
          path: 'condo/info',
          component: () => import('@/views/dashboard/Condo/Info')
        },
        {
          name: 'Condo Select Plan',
          path: 'condo/selectplan',
          component: () => import('@/views/dashboard/Condo/SelectPlan')
        },
        {
          name: 'Property Info',
          path: 'condo/propertyinfo',
          component: () => import('@/views/dashboard/Condo/PropertyInfo')
        },
        {
          name: 'Condo Pay',
          path: 'condo/pay',
          component: () => import('@/views/dashboard/Condo/Payment')
        },
        {
          name: 'Condo Coverages',
          path: 'condo/coverages',
          component: () => import('@/views/dashboard/Condo/Coverages')
        },
        {
          name: 'Condo Policy',
          path: 'condo/policy',
          component: () => import('@/views/dashboard/Condo/Policy')
        },
        // User
        {
          name: 'Profile',
          path: 'profile',
          component: () => import('@/views/dashboard/User/Profile'),
          beforeEnter: AuthGaurd
        },
        {
          name: 'My Profile',
          path: 'profile',
          component: () => import('@/views/dashboard/User/UserProfileCard'),
          beforeEnter: AuthGaurd
        },
        {
          name: 'Edit My Profile',
          path: 'userprofileupdate',
          component: () => import('@/views/dashboard/User/UserProfile'),
          beforeEnter: AuthGaurd
        },
        {
          name: 'Quotes for your Blanket',
          path: 'userquotes',
          component: () => import('@/views/dashboard/User/UserQuotes'),
          // beforeEnter: AuthGaurd
        },
        {
          name: 'My Blanket Policies',
          path: 'userpolicies',
          component: () => import('@/views/dashboard/User/UserPolicies'),
          beforeEnter: AuthGaurd
        },
        {
          name: 'My Blanket Applications',
          path: 'userapplications',
          component: () => import('@/views/dashboard/User/UserApplications.vue'),
          beforeEnter: AuthGaurd,
        },
        {
          name: 'Your Alerts',
          path: 'UserAlerts',
          component: () => import('@/views/dashboard/User/UserAlerts'),
          beforeEnter: AuthGaurd
        },
        // Travel Usa
        {
          name: 'Travel USA',
          path: 'travel/usa',
          // component: () => import('@/views/ProductUnavailable')
          component: () => import('@/views/dashboard/TravelUS/TravelUsa'),
          meta: {
            title: 'Travel Usa Page',
            canonical: 'https://www.blanket.com/travel/usa',
          }
        },
        {
          name: 'Travel USA',
          path: 'travel/usa/partner/:partnername/:campaignid?',
          props: { default: true },
          // component: () => import('@/views/ProductUnavailable')
          component: () => import('@/views/dashboard/TravelUS/TravelUsa'),
        },
        {
          name: 'Travel USA',
          path: 'travel/usa/partner/:partnername',
          props: { default: true },
          // component: () => import('@/views/ProductUnavailable')
          component: () => import('@/views/dashboard/TravelUS/TravelUsa'),
        },
        {
          name: 'Travel Info USA',
          path: 'travel/infousa',
          // component: () => import('@/views/ProductUnavailable')
          component: () => import('@/views/dashboard/TravelUS/TravelInfoUsa')
        },
        {
          name: 'Learn More USA',
          path: 'travel/learnmoreusa',
          // component: () => import('@/views/ProductUnavailable')
          component: () => import('@/views/dashboard/TravelUS/LearnMore')
        },
        {
          name: 'Travel Select Plan USA',
          path: 'travel/selectplanusa',
          // component: () => import('@/views/ProductUnavailable')
          component: () => import('@/views/dashboard/TravelUS/TravelSelectPlanUsa')
        },
        {
          name: 'Travel Pay USA',
          path: 'travel/payusa',
          // component: () => import('@/views/ProductUnavailable')
          component: () => import('@/views/dashboard/TravelUS/TravelPayUsa')
        },
        {
          name: 'Travel Policy USA',
          path: 'travel/policyusa',
          // component: () => import('@/views/ProductUnavailable')
          component: () => import('@/views/dashboard/TravelUS/TravelPolicyUsa')
        },
        {
          name: 'Travel Policies USA',
          path: 'travel/policiesusa',
          component: () => import('@/views/ProductUnavailable')
          // component: () => import('@/views/dashboard/TravelUS/TravelPoliciesUsa')
        },
        // Travel Canada
        {
          name: 'Travel',
          path: 'travelca',
          component: () => import('@/views/dashboard/TravelCA/TravelCa')
        },
        {
          name: 'Travel',
          path: 'travel/ca',
          component: () => import('@/views/dashboard/TravelCA/TravelCa2022'),
          meta: {
            title: 'Travel Ca Page',
            canonical: 'https://www.blanket.com/travelca',
          },
        },
        {
          name: 'Learn More',
          path: 'travelcalearnmore',
          component: () => import('@/views/dashboard/TravelCA/LearnMore')
        },
        {
          name: 'Canada Travel',
          path: 'travelcoverageoptionscanada',
          component: () => import('@/views/dashboard/TravelCA/TravelCoverageOptionsCanada')
        },
        {
          name: 'Canada Travel',
          path: 'travelinfocanada',
          component: () => import('@/views/dashboard/TravelCA/TravelInfoCanada')
        },
        {
          name: 'Personal Details',
          path: 'travelpersonaldetailsca',
          component: () => import('@/views/dashboard/TravelCA/TravelPersonalDetailsCa')
        },
        {
          name: 'Travel Policies',
          path: 'travelpolicyca',
          component: () => import('@/views/dashboard/TravelCA/TravelPolicyCa')
        },
        // Legal
        {
          name: 'Terms and Conditions',
          path: 'termsandconditions',
          component: () => import('@/views/dashboard/Legal/TermsAndConditions')
        },
        {
          name: 'Licenses',
          path: 'licenses',
          component: () => import('@/views/dashboard/Legal/Licenses'),
          beforeEnter: AuthGaurd
        },
        {
          name: 'Privacy Policy',
          path: 'privacy',
          component: () => import('@/views/dashboard/Legal/PrivacyPolicy'),
        },
        {
          name: 'Privacy Policy',
          path: 'privacy-ca',
          component: () => import('@/views/dashboard/Legal/PrivacyPolicyCa'),
        },
        {
          name: 'Complaints',
          path: 'complaints-ca',
          component: () => import('@/views/dashboard/Legal/ComplaintsCa'),
        },
        {
          name: 'Blanket Alert System',
          path: 'Alerts',
          component: () => import('@/views/dashboard/Alerts'),
          beforeEnter: AuthGaurd
        },
        // Parachute Canadian term
        {
          name: 'CA Term',
          path: 'canadianterm',
          component: () => import('@/views/dashboard/CATerm/CATerm'),
          meta: {
            title: 'Canadian Term Page',
            canonical: 'https://www.blanket.com/canadianterm',
          },
        },
        {
          name: 'CA Term Survey',
          path: 'canadianterm/partner/:partnerId',
          component: () => import('@/views/dashboard/CATerm/CATerm'),
        },
        {
          name: 'CA Term Survey',
          path: 'canadianterm/survey/:step/:applicationId?',
          component: () => import('@/views/dashboard/CATerm/CATermSurvey'),
        },
        {
          name: 'CA Term R&C',
          path: 'canadiantermrc',
          component: () => import('@/views/dashboard/CATermR&C/CATermR&C'),
        },
        {
          name: 'CA Term R&C Survey',
          path: 'canadiantermrc/survey',
          component: () => import('@/views/dashboard/CATermR&C/CATermR&CSurvey'),
        },
        {
          name: 'CA Term Calculator',
          path: 'canadianterm/coveragecalculator',
          component: () => import('@/views/dashboard/components/QuoteCoverageCalculator.vue'),
        },
        // Afficiency Termlife
        {
          name: 'Afficiency Term Home',
          path: 'afficiencyterm',
          beforeEnter: clearAfficiencyData,
          component: () => import('@/views/dashboard/AfficiencyTermLife/AfficiencyTerm'),
        },
        {
          name: 'Term Life Survey',
          path: 'afficiencyterm/survey',
          component: () => import('@/views/dashboard/AfficiencyTermLife/AfficiencySurvey'),
        },
        // Termlife
        {
          name: 'Term Life Home',
          path: 'termlifehome',
          component: () => import('@/views/dashboard/TermLife/TermLifeHome2022'),
        },
        {
          name: 'Term Life Survey',
          path: 'surveytermlife',
          component: () => import('@/views/dashboard/TermLife/SurveyTermLife'),
        },
        {
          name: 'Term Coverage Options',
          path: 'termcoverage',
          component: () => import('@/views/dashboard/TermLife/TermCoverage'),
        },
        {
          name: 'Special Handling',
          path: 'surveyspecialhandling',
          component: () => import('@/views/dashboard/SurveySpecialHandling'),
        },
        {
          name: 'Symetra Term Life Application',
          path: 'symetratermlifeapp/:key',
          props: { default: true },
          component: () => import('@/views/dashboard/TermLife/SymetraTermLifeApplication'),
        },
        {
          name: 'Anico Term Life Application',
          path: 'anicotermlifeapp/:key',
          props: { default: true },
          component: () => import('@/views/dashboard/TermLife/AnicoTermLifeApplication'),
        },
        // Pages
        {
          name: 'RTL',
          path: 'pages/rtl',
          component: () => import('@/views/dashboard/pages/Rtl'),
        },
        {
          name: 'User Profile',
          path: 'pages/user',
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },
        {
          name: 'Timeline',
          path: 'pages/timeline',
          component: () => import('@/views/dashboard/pages/Timeline'),
        },
        // Components
        {
          name: 'Buttons',
          path: 'components/buttons',
          component: () => import('@/views/dashboard/component/Buttons'),
        },
        {
          name: 'Grid System',
          path: 'components/grid-system',
          component: () => import('@/views/dashboard/component/Grid'),
        },
        {
          name: 'Tabs',
          path: 'components/tabs',
          component: () => import('@/views/dashboard/component/Tabs'),
        },
        {
          name: 'Notifications',
          path: 'components/notifications',
          component: () => import('@/views/dashboard/component/Notifications'),
        },
        {
          name: 'Icons',
          path: 'components/icons',
          component: () => import('@/views/dashboard/component/Icons'),
        },
        {
          name: 'Typography',
          path: 'components/typography',
          component: () => import('@/views/dashboard/component/Typography'),
        },
        // Forms
        {
          name: 'Regular Forms',
          path: 'forms/regular',
          component: () => import('@/views/dashboard/forms/RegularForms'),
        },
        {
          name: 'Extended Forms',
          path: 'forms/extended',
          component: () => import('@/views/dashboard/forms/ExtendedForms'),
        },
        {
          name: 'Validation Forms',
          path: 'forms/validation',
          component: () => import('@/views/dashboard/forms/ValidationForms'),
        },
        {
          name: 'Wizard',
          path: 'forms/wizard',
          component: () => import('@/views/dashboard/forms/Wizard'),
        },
        // Tables
        {
          name: 'Regular Tables',
          path: 'tables/regular-tables',
          component: () => import('@/views/dashboard/tables/RegularTables'),
        },
        {
          name: 'Extended Tables',
          path: 'tables/extended-tables',
          component: () => import('@/views/dashboard/tables/ExtendedTables'),
        },
        {
          name: 'Data Tabels',
          path: 'tables/data-tables',
          component: () => import('@/views/dashboard/tables/DataTables'),
        },
        // Maps
        {
          name: 'Google Maps',
          path: 'maps/google-maps',
          component: () => import('@/views/dashboard/maps/GoogleMaps'),
        },
        {
          name: 'Full Screen Map',
          path: 'maps/full-screen-map',
          component: () => import('@/views/dashboard/maps/FullScreenMap'),
        },
        // Root level
        {
          name: 'Widgets',
          path: 'widgets',
          component: () => import('@/views/dashboard/Widgets'),
        },
        {
          name: 'Charts',
          path: 'charts',
          component: () => import('@/views/dashboard/Charts'),
        },
        {
          name: 'Calendar',
          path: 'calendar',
          component: () => import('@/views/dashboard/Calendar'),
        },
        {
          name: 'Bncdashboard',
          path: 'bnmdashboard',
          component: () => import('@/views/dashboard/BNM/BNMDashboard'),
          meta: {
            title: 'Bncdashboard Page',
            canonical: 'https://www.blanket.com/bnmdashboard',
          },
        },
        {
          name: 'Quote',
          path: 'quote',
          component: () => import('@/views/dashboard/CATerm/Quote')
        },
      ],
    },
    {
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/views/pages/Error'),
          meta: {
            title: '404 Not Found Page',
            canonical: 'https://www.blanket.com/Error',
          },
        },
      ],
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
