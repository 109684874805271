import { firebase } from '@firebase/app'
import '@firebase/database'
import '@firebase/storage'
import 'firebase/functions'
import 'firebase/auth'
import moment from 'moment'
import router from '../router'
import { v4 as uuidv4 } from 'uuid'
import ShortUniqueId from 'short-unique-id'
import IPinfoWrapper, { IPinfo, AsnResponse } from 'node-ipinfo'
import i18n from '../i18n'

const ipinfo = new IPinfoWrapper('7e86578a8a6101')
var { nanoid } = require('nanoid')

const axios = require('axios')
var numeral = require('numeral')
const zipcodes = require('zipcodes')

const downloadCSV = (csvContent, reportType = '') => {
  const blob = new Blob([csvContent], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)
  const currentDate = moment().format('YYYY-MM-DD')
  const anchor = document.createElement('a')
  anchor.href = url
  let getReportType = reportType
  if (reportType === 'userjourney') {
    getReportType = 'CA Term User Journey Report'
  }
  if (['all', 'cst', 'gga'].includes(reportType)) {
    getReportType = `${reportType}_partner`
  }
  anchor.download = `${getReportType}_${currentDate}.csv`
  anchor.click()
  window.URL.revokeObjectURL(url)
}
export default {
  setLoading ({ commit }, payload) {
    commit('setLoading', payload)
  },
  setPrefferedLang ({ commit }, payload) {
    commit('setPrefferedLang', payload)
  },
  clearError ({ commit }) {
    commit('clearError')
  },
  clearLoginError ({ commit }) {
    commit('setloginError', null)
  },
  sendRecoveryEmail ({ commit }, payload) {
    commit('setLoading', true)
    commit('setLoading', false)
    var auth = firebase.auth()
    var emailAddress = payload.email
    auth.sendPasswordResetEmail(emailAddress).then(function () {
      // console.log('Email was sent to the user address!!', payload.email)
      // Email sent.
      commit('setError', 'Recovery email has been sent, please check your email.')
    })
      .catch(
        error => {
          commit('setError', error)
          console.log(error)
        }
      )
  },
  autoSignIn ({ commit, dispatch }, payload) {
    let key = payload.uid
    // firebase.database().ref('users').child(key)
    /*
    firebase.database().ref('/users/' + key).once('value').then(function (snapshot) {
      console.log('snap is ', snapshot.val())
    })
    */
    firebase.database().ref('users').child(key).once('value')
      .then(
        data => {
          const obj = data.val()
          let feet = ''
          let inches = ''
          let height = {
            feet: feet,
            inches: inches
          }
          const userData = firebase.auth().currentUser
          if (obj.height) {
            height.feet = obj.height.feet
            height.inches = obj.height.inches
          }
          const newUser = {
            id: payload.uid,
            alerts: obj.alerts,
            policies: obj.policies,
            applications: obj.applications ?? [],
            height: height,
            bdays: obj.bdays,
            bmonths: obj.bmonths,
            byears: obj.byears,
            gender: obj.gender,
            heightinches: obj.heightinches,
            heightfeet: obj.heightfeet,
            weightpounds: obj.weightpounds,
            weightkilos: obj.weightkilos,
            weight: obj.weight,
            dob: obj.dob,
            ssn: obj.ssn,
            email: obj.email,
            level: obj.level,
            status: obj.status,
            firstname: obj.firstname,
            lastname: obj.lastname,
            mobile: obj.mobile,
            address: obj.address,
            state: obj.state,
            city: obj.city,
            country: obj.country,
            zipcode: obj.zipcode,
            emailVerified: userData?.emailVerified,
            preferredLang: obj?.preferredLang
          }
          console.log('setting user from auto sign in')
          commit('setUser', newUser)
          console.log('newUser', newUser)
          const getLocale = i18n?.locale || 'en'
          dispatch('updatelang', {
            languageCode: getLocale
          })
        }
      )
      .catch(
        error => {
          commit('setError', error)
          console.log(error)
        }
      )
  },
  async createUserAndProfileForUser ({ commit, state }, payload) {
    try {
      commit('setLoading', true)
      commit('clearLoginError')

      if (!payload || !payload.email) {
        throw new Error('Invalid payload: email is required')
      }

      // Check if user is already signed in
      if (firebase.auth().currentUser) {
        console.log('User already signed in')
        return
      }

      // Create new user
      const password = moment().format('YYYYMMDDmmss')
      const userResponse = await firebase.auth().createUserWithEmailAndPassword(payload.email, payload?.password || password)
      const userId = userResponse.user.uid
      commit('setcurrentuserkey', userId)

      // Prepare user profile
      const userProfile = {
        email: payload.email,
        preferredLang: payload.preferredLang,
        height: { feet: 0, inches: 0 },
        weight: 0,
        campaignId: state.campaignId || 'unknown',
        partnerId: state.partnerId || 'unknown',
        group: 'UNSET',
        level: 0,
        status: 'NONE',
        ssn: '',
        dob: '',
        driverlicense: '',
        bio: 'nothing',
        alerts: [],
        policies: [],
        firstname: '',
        lastname: '',
        mobile: '',
        address: '',
        city: '',
        country: 'US',
        postcode: '',
      }
      console.log('userProfile>>>>', userProfile)
      // Create and set user profile
      await firebase.database().ref('/users/' + userId).update(userProfile)
      commit('setLoading', false)
    } catch (error) {
      console.error('Error creating user and profile', error)
      commit('setLoading', false)
      commit('setloginError', error.message)
      commit('setError', error.message)
    }
  },
  createUserAndProfile ({ commit, state }, payload) {
    // if i have a current signed in user then there is nothing to do!
    if (firebase.auth().currentUser.uid) {
      return
    }
    // create a user and profile without them signing up
    // then load that profile
    let useremail = payload.email
    let password = payload.password
    // populate from whatever is set in the current state about this user that was not registered
    let userdetails = state.user
    firebase.auth().createUser({
      email: useremail,
      emailVerified: true,
      password: password,
      disabled: false,
    })
      .then(
        userRecord => {
          let partnerId = 'unknown'
          partnerId = state.partnerId
          let campaignId = 'unknown'
          campaignId = state.campaignId
          let idkey = userRecord.uid
          const newUser = {
            id: payload.uid,
            alerts: '',
            policies: '',
            campaignId: campaignId,
            partnerId: partnerId,
            height: 0,
            weight: 0,
            dob: '',
            ssn: '',
            email: useremail,
            level: 0,
            status: '',
            username: '',
            firstname: 'first',
            lastname: 'last',
            mobile: '',
            address: '',
            city: '',
            country: '',
            postcode: '',
          }
          firebase.database().ref('/users/' + idkey).update(newUser)
            .then(
              console.log('user created with profile')
            )
            .catch(
              error => {
                console.log(error)
              }
            )
        }
      )
      .catch(
        error => {
          console.log('error ', error)
        }
      )
  },
  clearLoginResponse ({ commit }) {
    commit('clearLoginResponse')
  },

  sendpassreset ({ commit }, payload) {
    let emailAddress = payload
    firebase.auth().sendPasswordResetEmail(emailAddress).then(function () {
      // Email sent.
    }).catch(function (error) {
      console.log(error)
    })
  },
  signUserInGoogle ({ commit }, payload) {
    commit('setLoading', true)
    commit('clearError')
    var provider = new firebase.auth.GoogleAuthProvider()
    // [END createprovider]
    // [START addscopes]
    firebase.auth().signInWithPopup(provider)
      .then(
        result => {
          console.log('**** user uid is: ' + result)
          var token = result.credential.accessToken
          // The signed-in user info.
          var googleuser = result.user
          console.log('info is: ', token, googleuser)
          console.log('name is: ', googleuser.displayName)
          commit('setDisplayname', googleuser.displayName)
          commit('setLoading', false)
        }
      )
      .catch(
        error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        }
      )
  },
  signUserInTwitter ({ commit }, payload) {
    commit('setLoading', true)
    commit('clearError')
    var provider = new firebase.auth.TwitterAuthProvider()
    // [END createprovider]
    // [START addscopes]
    firebase.auth().signInWithPopup(provider)
      .then(
        result => {
          console.log('**** user uid is: ' + result)
          var token = result.credential.accessToken
          // The signed-in user info.
          var twitteruser = result.user
          console.log('info is: ', token, twitteruser)
          console.log('name is: ', twitteruser.displayName)
          commit('setDisplayname', twitteruser.displayName)
          commit('setLoading', false)
        }
      )
      .catch(
        error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        }
      )
  },
  signUserInFacebook ({ commit }, payload) {
    commit('setLoading', true)
    commit('clearError')
    var provider = new firebase.auth.FacebookAuthProvider()
    // [END createprovider]
    // [START addscopes]
    firebase.auth().signInWithPopup(provider)
      .then(
        result => {
          console.log('**** user uid is: ' + result)
          var token = result.credential.accessToken
          // The signed-in user info.
          var fbuser = result.user
          console.log('info is: ', token, fbuser)
          console.log('name is: ', fbuser.displayName)
          commit('setDisplayname', fbuser.displayName)
          commit('setLoading', false)
        }
      )
      .catch(
        error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        }
      )
  },
  async createApplicantAccount ({ commit, state }, payload) {
    try {
      commit('setLoading', true)

      await firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password)
      const idkey = firebase.auth().currentUser.uid

      const myuser = {
        email: payload.email,
        height: { feet: 0, inches: 0 },
        weight: 0,
        group: 'UNSET',
        partnerId: state.partnerId || 'unknown',
        campaignId: state.campaignId || 'unknown',
        level: 0,
        status: 'NONE',
        ssn: '',
        dob: '',
        driverlicense: '',
        bio: 'nothing',
        alerts: [],
        policies: [],
        firstname: '',
        lastname: '',
        mobile: '',
        address: '',
        city: '',
        country: 'US',
        postcode: '',
      }

      console.log('#####   partner ', state.partnerId, ' campaign ', myuser)

      try {
        await firebase.database().ref('/users/' + idkey).update(myuser)
      } finally { // Using finally block to ensure setLoading is called regardless of the outcome
        commit('setLoading', false)
      }
    } catch (error) {
      commit('setloginError', error.message)
      commit('setLoading', false)
    }
  },

  async signApplicantIn ({ commit }, payload) {
    try {
      commit('clearError')
      commit('setLoading', true)

      const userCredential = await firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
      const userId = userCredential.user.uid

      const data = await firebase.database().ref('users').child(userId).once('value')
      const obj = data.val()

      const userTemplate = {
        key: '',
        email: '',
        height: { feet: 0, inches: 0 },
        weight: 0,
        group: 'UNSET',
        level: 0,
        status: 'NONE',
        ssn: '',
        dob: '',
        driverlicense: '',
        bio: 'nothing',
        alerts: [],
        policies: [],
        firstname: '',
        lastname: '',
        mobile: '',
        address: '',
        city: '',
        country: 'US',
        postcode: '',
      }

      const myuser = {
        ...userTemplate,
        ...obj,
        key: userId,
        id: userId,
      }

      console.log('setting user data from signUserIn', myuser)

      commit('setUser', myuser)
      commit('setcurrentuserkey', userId)
    } catch (error) {
      const result = JSON.parse(JSON.stringify(error))
      commit('setloginError', result.message)
    } finally {
      commit('setLoading', false)
    }
  },

  async signUserIn ({ commit }, payload) {
    try {
      commit('clearError')
      commit('clearLoginError')
      commit('setLoading', true)
      const userCredential = await firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
      const userId = userCredential.user.uid
      const data = await firebase.database().ref('users').child(userId).once('value')
      const obj = data.val()

      const userTemplate = {
        key: '',
        email: '',
        applications: [],
        height: { feet: 0, inches: 0 },
        weight: 0,
        group: 'UNSET',
        level: 0,
        status: 'NONE',
        ssn: '',
        dob: '',
        driverlicense: '',
        bio: 'nothing',
        alerts: [],
        policies: [],
        firstname: '',
        lastname: '',
        mobile: '',
        address: '',
        city: '',
        country: 'US',
        postcode: '',
      }

      const myuser = {
        ...userTemplate,
        ...obj,
        key: userId,
        id: userId,
      }

      console.log('setting user data from signUserIn', myuser)

      commit('setUser', myuser)
      commit('setcurrentuserkey', userId)
    } catch (error) {
      const result = JSON.parse(JSON.stringify(error))
      console.log('error', result)

      commit('setloginError', result.message)
      commit('setError', result.message)
    } finally {
      commit('setLoading', false)
    }
  },
  signUserUp ({ commit, state }, payload) {
    // commit('clearError')
    firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password)
      .then(
        user => {
          let idkey = firebase.auth().currentUser.uid
          let partnerId = 'unknown'
          partnerId = state.partnerId
          let campaignId = 'unknown'
          campaignId = state.campaignId
          const myuser = {
            email: payload.email,
            emailVerified: payload.isEmailVerified,
            height: {
              feet: 0,
              inches: 0
            },
            weight: 0,
            group: 'UNSET',
            partnerId: partnerId,
            campaignId: campaignId,
            level: 0,
            status: 'NONE',
            ssn: '',
            dob: '',
            driverlicense: '',
            bio: 'nothing',
            alerts: [],
            policies: [],
            applications: [],
            firstname: '',
            lastname: '',
            mobile: '',
            address: '',
            city: '',
            country: 'US',
            postcode: '',
          }
          console.log('#####   partner ', partnerId, ' campaign ', myuser)
          firebase.database().ref('/users/' + idkey).update(myuser)
            .then((data) => {
              console.log('user has been created')
              router.push('/')
            })
            .catch(
              error => {
                commit('setLoading', false)
                console.log('WE GOT A USER PROFILE ERROR ', error)
              }
            )
        }
      )
      .catch(
        error => {
          let result = JSON.parse(JSON.stringify(error))
          commit('setloginError', result.message)
          console.log('error ', JSON.parse(JSON.stringify(error)))
          commit('setError', result.message)
        }
      )
  },
  signUserOut ({ commit }) {
    firebase.auth().signOut().then(function () {
      console.log('user signed out')
      commit('setUser', null)
      // Sign-out successful.
    }).catch(function (error) {
      console.log('error ', error)
      commit('setError', error)
    })
  },
  // partner functions
  loadPartners ({ commit }) {
    // get all partners in the database
  },
  loaddataspy ({ commit }) {
    let applicants = []
    firebase.database().ref('dataspy').child('bnc').child('applicants').once('value')
      .then((data) => {
        const obj = data.val()
        if (obj) {
          for (let key in obj) {
            applicants.push({
              key: key,
              Address: obj[key].Address,
              AnnualPremium: obj[key].AnnualPremium,
              City: obj[key].City,
              Email: obj[key].Email,
              Industry: obj[key].Industry,
              Name: obj[key].Name,
              Occupation: obj[key].Occupation,
              State: obj[key].State,
              Zipcode: obj[key].Zipcode,
              propertydetail: obj[key].propertydetail,
              saleshistory: obj[key].saleshistory,
              valuation: obj[key].valuation
            })
          }
        }
      })
      .then(() => {
        commit('setDataSpyApplicants', applicants)
      })
  },
  setPartnerId ({ commit }, payload) {
    let partnerId = 'unknown'
    partnerId = payload
    console.log('partner id passed in aciton ', payload)
    commit('setPartnerId', partnerId)
  },
  setPartnerCampaignId ({ commit }, payload) {
    let campaignId = 'unknown'
    campaignId = payload
    console.log('campaign id passed in action ', payload)
    commit('setCampaignId', campaignId)
  },

  // createBlanketPartner ({ commit }, payload) {
  //   let partnerRec = {
  //     type: payload.type ?? '',
  //     timestamp: Date.now(),
  //     active: payload.active ?? false, // if we didn't get a param, make it false
  //     company: payload.company,
  //     companywebsite: payload.companywebsite,
  //     email: payload.email,
  //     industries: payload.industries,
  //     limitedLineTravelInsuranceLicense: payload.limitedLineTravelInsuranceLicense ?? "I don't know",
  //     limitedLineTravelInsuranceLicenseNumber: payload.limitedLineTravelInsuranceLicenseNumber ?? '',
  //     message: payload.message,
  //     name: payload.name,
  //     notes: payload.notes,
  //     partnerurl: payload.partnerurl,
  //     phone: payload.phone,
  //     approvedTimestamp: payload.active === 'APPROVED' ? new Date().getTime() : null
  //   }
  //   commit('setLoading', true)
  //   return new Promise((resolve, reject) => {
  //     firebase.database().ref('partners').push(partnerRec)
  //     .then((response) => {
  //       commit('setLoading', false)
  //       resolve(response)
  //     })
  //     .catch(
  //       (error) => {
  //         console.log('[actions > createBlanketPartner] Error pushing to partners: ', error)
  //         commit('setLoading', false)
  //         reject(error)
  //       }
  //     )
  //   })
  //   // mrmr part
  // },

  createBlanketPartner ({ commit }, payload) {
    let partnerRec = {
      type: payload.type ?? '',
      timestamp: Date.now(),
      active: payload.active ?? false,
      company: payload.company,
      companywebsite: payload.companywebsite,
      email: payload.email,
      industries: payload.industries,
      limitedLineTravelInsuranceLicense: payload.limitedLineTravelInsuranceLicense ?? "I don't know",
      limitedLineTravelInsuranceLicenseNumber: payload.limitedLineTravelInsuranceLicenseNumber ?? '',
      message: payload.message,
      name: payload.name,
      notes: payload.notes,
      partnerurl: payload.partnerurl,
      phone: payload.phone,
      approvedTimestamp: payload.active === 'APPROVED' ? new Date().getTime() : null
    }

    commit('setLoading', true)

    const checkPartnerCompanyName = firebase.functions().httpsCallable('checkPartnerCompanyName')

    return new Promise((resolve, reject) => {
      checkPartnerCompanyName({ company: payload.company })
        .then(result => {
          if (result.data.exists) {
            commit('setError', 'This company already has a contract with Blanket. Please enter a different company name or contact us at partnerships@blanket.com or 1(833) 333-3145')
            commit('setLoading', false)
          } else {
            firebase.database().ref('partners').push(partnerRec)
              .then(response => {
                commit('setLoading', false)
                resolve(response)
              })
              .catch(error => {
                console.log('[actions > createBlanketPartner] Error pushing to partners: ', error)
                commit('setLoading', false)
                reject(error)
              })
          }
        })
        .catch(error => {
          commit('setLoading', false)
          reject(error)
        })
    })
  },

  updateBlanketPartner ({ commit }, payload) {
    let partnerRec = {
      email: payload.email,
      name: payload.name,
      message: payload.message,
      active: payload.active ?? false, // if we didn't get a param, make it false
      phone: payload.phone,
      company: payload.company,
      companywebsite: payload.companywebsite,
      industries: payload.industries,
      limitedLineTravelInsuranceLicense: payload.limitedLineTravelInsuranceLicense ?? "I don't know",
      limitedLineTravelInsuranceLicenseNumber: payload.limitedLineTravelInsuranceLicenseNumber ?? '',
      partnerurl: payload.partnerurl,
      key: payload.key,
      notes: payload.notes,
      approvedTimestamp: payload.active === 'APPROVED' ? new Date().getTime() : null
    }

    if (payload.type) {
      partnerRec.type = payload.type
    }

    commit('setLoading', true)
    firebase.database().ref('partners').child(payload.key).update(partnerRec)
      .then(() => {
        commit('setLoading', false)
      })
      .catch(
        (error) => {
          console.log('error pushing to partners ', error)
          commit('setLoading', false)
        }
      )
    // mrmr part
  },
  loadblanketpartners ({ commit }) {
    commit('setLoading', true)
    firebase.database().ref('partners')
      .on('value', data => {
        let partners = []

        const obj = data.val()
        for (let key in obj) {
          let partnerurl = ''
          if (obj[key].partnerurl) {
            partnerurl = obj[key].partnerurl
          }
          if (obj[key].isDeleted === false || obj[key].isDeleted === null || obj[key].isDeleted === undefined) {
            partners.push({
              type: obj[key].type,
              timestamp: obj[key].timestamp,
              name: obj[key].name ?? obj[key]?.first_name + ' ' + obj[key]?.last_name,
              email: obj[key].email,
              message: obj[key].message,
              active: obj[key].active ?? false, // if we didn't get a param, make it false
              phone: obj[key].phone,
              company: obj[key].company,
              companywebsite: obj[key].companywebsite,
              industries: obj[key].industries,
              limitedLineTravelInsuranceLicense: obj[key].limitedLineTravelInsuranceLicense ?? "I don't know",
              limitedLineTravelInsuranceLicenseNumber: obj[key].limitedLineTravelInsuranceLicenseNumber ?? '',
              approvedTimestamp: obj[key].approvedTimestamp,
              partnerurl: partnerurl,
              key: key,
              notes: obj[key].notes,
            })
          }
        }
        partners.sort((a, b) => a.timestamp - b.timestamp)
        commit('setLoadedBlanketPartners', partners)
        commit('setLoading', false)
      }, error => {
        commit('setLoadedBlanketPartners', [])
        console.log(error)
      })
  },
  createPartnerCampaign ({ commit }, payload) {
    console.log('payload', payload)
    let partnerkey = payload.key
    let campRec = {
      idnum: 0,
      label: '',
      startDate: -1,
      endDate: -1,
      timestamp: Date.now()
    }
    campRec.idnum = payload.idnum
    campRec.label = payload.label
    campRec.startDate = payload.startDate
    campRec.endDate = payload.endDate
    console.log('camp rec ', campRec)
    firebase.database().ref('partners').child(partnerkey).child('campaigns').push(campRec)
  },
  updatePartnerCampaign ({ commit }, payload) {
    let partnerkey = payload.partnerkey
    let campaignkey = payload.campaignkey
  },
  loadPartnerCampaign ({ commit }, payload) {
    let partnerkey = payload
    commit('setLoadedPartnerCampaigns', [])
    commit('setLoading', true)
    let campaigns = []
    firebase.database().ref('partners').child(partnerkey).child('campaigns').once('value')
      .then((data) => {
        const obj = data.val()
        for (let key in obj) {
          campaigns.push({
            timestamp: obj[key].timestamp,
            label: obj[key].label,
            idnum: obj[key].idnum,
            startDate: obj[key].startDate,
            endDate: obj[key].endDate,
            key: key
          })
        }
        console.log('setting campaigns', campaigns)
        commit('setLoadedPartnerCampaigns', campaigns)
        commit('setLoading', false)
      })
      .catch(
        (error) => {
          commit('setLoadedBlanketPartners', [])
          console.log(error)
        }
      )
  },
  loadAlerts ({ commit }) {
    const alerts = []
    firebase.database().ref('ALERTS').once('value')
      .then((data) => {
        const obj = data.val()
        for (let key in obj) {
          alerts.push({
            label: obj[key].label,
            event_type: obj[key].event_type,
            method: obj[key].method,
            destination: obj[key].destination,
            key: key
          })
        }
        console.log('setting alerts')
        commit('setLoadedAlerts', alerts)
      })
      .catch(
        (error) => {
          commit('setLoadedAlerts', alerts)
          console.log(error)
        }
      )
  },

  async testuser () {
    console.log('admin sec test!!!')
    firebase.database().ref('users').push({ test: 4321 })
      .catch(
        (error) => {
          console.log('error pushing to users ', error)
        }
      )
  },
  async trunpanionPaymentParamsRequest ({ commit }, payload) {
    commit('setLoading', true)
    commit('setTrupanionPaymentParams', '') // reset it

    // let params = {
    //   id: '8ad0824e7ea9dea1017eb12c4ccf3824',
    //   tenantId: '13408',
    //   token: 'BbY4dn1vg0RMjs8Q2Y69IQWl9QwPKNZh',
    //   signature: 'QKpsOsI05ldmxYNZ7uN6WwSTxXkxWjbovGUk8j9AZjJJWFHZ8gGGjI03SgaqtkgaF40LrI2LPAiG+8aGU6H1UQEBBLY77tnxvWcGsx0R1/ZT1FhJP59jLTtDixiB08uUmehfWn2EdZXeFoF5j3LogZfj/85cTPPlehmZ47yqaO+EWqYXCJKkpL4wxPVzKMeMICO2iC5nwUAzBIKQVkaZVCcvAIiXbxajUcyY6E0ZURRUwTjy92DCM4gUEYeM20OdRpuiwyoSiL+ydN9EvkjFtngkEb2oO05qox3cvZAaYNhX2PyH9dRUsMUHt28q+cak+vtNEIa3JHAvaYVIuiZ4CA==',
    //   locale: 'en-US',
    //   param_supportedTypes: 'AmericanExpress,Visa,MasterCard,Discover',
    //   style: 'inline',
    //   key: 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAj8U/MdYVLXGKlHBIhxLRPcJNI+cFbVfTklbBvS1xnMiyLPHuStU/JAejXEinyPwYFpJFMsEC1prJ5Lb85UoBrQ83V7DhMt36ZhBXiuvFHaO83NwhKsPOer0C2jYNj2lsSpQxM8D7ji84GB8h5BIqDAgE8q+bKnvszA3KMaGUf20Ey8gpCw5EeoyQhxiaJicPbqVI5citqStYyDHGS7YakfoVCZ6Ji9STuJ7d97uPXffoJ5Yqi+AZ0vDvr1dBK8AJ+TGNW1LxjKN5uuKCJ+jlc1YgkEfvMPZRO/IykA0dg/wUCjFOhsWeaICVnI2+L1xHkKciFHIFZf+gsA/5vBasjwIDAQAB',
    //   submitEnabled: 'true',
    //   url: 'https://apisandbox.zuora.com/apps/PublicHostedPageLite.do',
    //   // paymentGateway: 'DefaultGateway'
    // }
    let trupantionPaymentParams = firebase.functions().httpsCallable('trupantionPaymentParams')
    trupantionPaymentParams()
      .then((data) => {
        console.log('######### Trupanion payment params returned  ', data.data.response)
        commit('setTrupanionPaymentParams', data.data.response)

        commit('setLoading', false)
        // set loading false
      })
      .catch(
        (error) => {
          console.log(error)
          commit('setLoading', false)
        }
      )
  },
  // USA travel section
  clearPurchaseError ({ commit }) {
    commit('setPurchaseError', '')
  },
  async purchaseTravelQuoteUSA ({ commit }, payload) {
    commit('setLoading', true)
    commit('setPurchaseError', '')
    let productclass = 'GGA100'
    if (payload.productclass) {
      productclass = payload.productclass
    }
    const uid = new ShortUniqueId({ length: 10 })

    console.log('request travel PURCHASE!!! from functions!! ', payload)
    let rec
    if (payload.options) {
      rec = {
        userkey: firebase.auth().currentUser.uid,
        emailaddress: payload.emailaddress,
        productclass: productclass,
        actioncode: 'NEW',
        bookingreservno: uid(),
        numinsured: payload.numinsured,
        departdate: payload.departdate,
        returndate: payload.returndate,
        triptype: payload.triptype,
        destination: payload.destination,
        tripcost: payload.tripcost,
        travelerfirstname: payload.travelerfirstname,
        travelerlastname: payload.travelerlastname,
        age: payload.age,
        address1: payload.address1,
        city: payload.city,
        state: payload.state,
        zipcode: payload.zipcode,
        telephonehome: payload.telephonehome,
        printpolconfltr: 1,
        price: payload.price,
        options: payload.options,
        paymentmethod: payload.paymentmethod,
        ccorcheckno: payload.ccorcheckno,
        ccexpiration: payload.ccexpiration,
        ccname: payload.ccname,
        cvv: payload.cvv,
        cczipcode: payload.cczipcode,
        partnerId: payload?.partnerId,
        campaignId: payload?.campaignId
      }
    } else {
      rec = {
        userkey: firebase.auth().currentUser.uid,
        emailaddress: payload.emailaddress,
        productclass: productclass,
        actioncode: 'NEW',
        bookingreservno: uid(),
        numinsured: payload.numinsured,
        departdate: payload.departdate,
        returndate: payload.returndate,
        triptype: payload.triptype,
        destination: payload.destination,
        tripcost: payload.tripcost,
        travelerfirstname: payload.travelerfirstname,
        travelerlastname: payload.travelerlastname,
        age: payload.age,
        address1: payload.address1,
        city: payload.city,
        state: payload.state,
        zipcode: payload.zipcode,
        telephonehome: payload.telephonehome,
        printpolconfltr: 1,
        price: payload.price,
        paymentmethod: payload.paymentmethod,
        ccorcheckno: payload.ccorcheckno,
        ccexpiration: payload.ccexpiration,
        ccname: payload.ccname,
        cvv: payload.cvv,
        cczipcode: payload.cczipcode,
        partnerId: payload?.partnerId,
        campaignId: payload?.campaignId
      }
    }
    console.log('REC', rec)

    let purchaseUSATravelQuote = firebase.functions().httpsCallable('purchaseUSATravelQuote')
    purchaseUSATravelQuote(rec)
      .then(async (data) => {
        console.log('whole purchase response from USA TRAVEL api server ', data.data)
        console.log('purchase response from USA TRAVEL api server ', data.data.response)
        let quotePrice = data.data.response
        const { policyInfo = null } = data.data.response
        let userkey = firebase.auth().currentUser.uid
        console.log('adapter returned simple ', quotePrice)
        console.log('adapter returned simple error flag ', quotePrice.haserror, quotePrice.haserror === false)
        commit('setLoading', false)

        // console.log('adapter returned ', quotePrice[0])
        if (quotePrice.haserror === false) {
          console.log('show them the policy')
          commit('setTravelUSAPolicy', quotePrice)
          commit('setLoading', false)
          commit('setIsPolicyPurchased', true)
          if (policyInfo) {
            const sendPolicy = firebase.functions().httpsCallable('sendGeneraliPolicy')
            sendPolicy(policyInfo)
          }
        }
        if (quotePrice[0] && quotePrice[0].haserror === true) {
          let message = ['Unknown Error']
          message = quotePrice[0].message
          console.log('purchase error is ', message)
          commit('setPurchaseError', message)
          commit('setLoading', false)
        } else {
          console.log('no error seen ', quotePrice)
          commit('setTravelUSAPolicy', quotePrice)
          commit('setLoading', false)
        }
        // set loading false
      })
      .catch(
        (error) => {
          console.log('from catch', error)
          commit('setPurchaseError', 'There was an issue with this request')
          commit('setLoading', false)
        }
      ).finally(() => {
      commit('setLoading', false)
    })
  },
  async getRentalCarCost ({ commit }, payload) {
    console.log('Getting rental car cost')
    let age = payload.age
    let tripcost = payload.tripcost
    let destination = payload.destination.toUpperCase()
    let numinsured = payload.numinsured
    let triptype = payload.triptype
    let startDate = payload.startDate
    let endDate = payload.endDate
    let rec = {
      numinsured: numinsured,
      departdate: moment(payload.departdate).format('YYYY-MM-DD'),
      returndate: moment(payload.returndate).format('YYYY-MM-DD'),
      triptype: triptype,
      tripcost: tripcost,
      destination: destination,
      agesOfTravellers: age,
      options: {
        collisionDamageWaiver:
          {
            startDate: startDate,
            endDate: endDate
          }
      }
    }
    let getUSATravelQuote = firebase.functions().httpsCallable('getUSATravelQuote')
    return getUSATravelQuote(rec)
      .then((data) => {
        console.log(rec)
        console.log('response from USA TRAVEL api server ', data.data.response)
        let prefprice = Number(data.data.response.mediumquote.quoteresponse.price[0])
        let stdprice = Number(data.data.response.smallquote.quoteresponse.price[0])
        commit('setUSARentalPricePref', prefprice)
        commit('setUSARentalPriceStd', stdprice)
        commit('setLoading', false)
      })
      .catch(
        (error) => {
          console.log(error)
        }
      )
  },
  async getTravelQuoteUSA ({ commit }, payload) {
    commit('setLoading', true)
    console.log('get usa travel quote ', payload)
    let age = payload.age
    let tripcost = payload.tripcost
    let destination = payload.destination.toUpperCase()
    let numinsured = payload.numinsured
    let triptype = payload.triptype
    let rec = {
      numinsured: numinsured,
      departdate: moment(payload.departdate).format('YYYY-MM-DD'),
      returndate: moment(payload.returndate).format('YYYY-MM-DD'),
      triptype: triptype,
      tripcost: tripcost,
      destination: destination,
      agesOfTravellers: age
    }

    let getUSATravelQuote = firebase.functions().httpsCallable('getUSATravelQuote')
    return getUSATravelQuote(rec)
      .then((data) => {
        console.log('response from USA TRAVEL api server ', data.data.response)
        let quote = data.data.response
        let smallquote = data.data.response.smallquote.quoteresponse
        let mediumquote = data.data.response.mediumquote.quoteresponse
        let largequote = data.data.response.largequote.quoteresponse
        if (!smallquote.error && !mediumquote.error && !largequote.error) {
          let premprice = Number(largequote.price[0])
          let prefprice = Number(mediumquote.price[0])
          let stdprice = Number(smallquote.price[0])
          commit('setTravelUSAQuote', quote)
          commit('setUSATravelCostPrem', premprice)
          commit('setUSATravelCostPref', prefprice)
          commit('setUSATravelCostStd', stdprice)
          commit('setLoading', false)
        } else {
          let errorMessage = smallquote.error[0].message[0]
          console.log('our error messages ', errorMessage)
          commit('setError', 'There was an issue with this request: ' + errorMessage)
          commit('setLoading', false)
        }
      })
      .catch(
        (error) => {
          console.log(error)
          commit('setLoading', false)
        }
      )
  },

  async getGeneraliCancellationCost ({ commit }, payload) {
    commit('setLoading', true)
    let age = payload.age
    let tripcost = payload.tripcost
    let destination = payload.destination.toUpperCase()
    let numinsured = payload.numinsured
    let triptype = payload.triptype
    let rec = {
      numinsured: numinsured,
      departdate: moment(payload.departdate).format('YYYY-MM-DD'),
      returndate: moment(payload.returndate).format('YYYY-MM-DD'),
      triptype: triptype,
      tripcost: tripcost,
      destination: destination,
      agesOfTravellers: age,
      options:
        {
          cancelAnyReason:
            {
              initialPaymentDate: moment().format('YYYY-MM-DD'),
            },
        }
    }

    let getGeneraliCancellationCost = firebase.functions().httpsCallable('getUSATravelQuote')
    return getGeneraliCancellationCost(rec)
      .then((data) => {
        console.log('CANCELLATION COST', data.data.response)
        let price = Number(data.data.response.largequote.quoteresponse.price[0])
        console.log('PRICE FROM CANCELLATION COST', price)
        commit('setUSACancellationCost', price)
        commit('setLoading', false)

        // set loading false
      })
      .catch(
        (error) => {
          console.log(error)
          commit('setLoading', false)
        }
      )
  },
  setCFARCost ({ commit }, payload) {
    commit('setLoading', true)
    commit('setCFARCost')
    commit('setLoading', false)
  },
  setRentalDamagePricePref ({ commit }, payload) {
    commit('setRentalDamagePricePref')
  },
  setRentalDamagePriceStd ({ commit }, payload) {
    commit('setRentalDamagePriceStd')
  },
  async getTravelSurveyCanada ({ commit }) {
    console.log('getting canadian travel survey')
    let getCanadaTravelSurvey = firebase.functions().httpsCallable('getCanadaTravelSurvey')
    getCanadaTravelSurvey()
      .then((data) => {
        console.log('response from TUGO CANADIAN TRAVEL api server for Get Survey', data.data.data.surveys)
        let surveyData = data.data.data.surveys

        commit('setTravelCanadaSurveys', surveyData)
        commit('setLoading', false)

        // set loading false
      })
      .catch(
        (error) => {
          console.log(error)
          commit('setLoading', false)
        }
      )
  },

  async getTravelQuoteCanada ({ commit }, payload) {
    //   {"productLineCode":"PR-FDM-1","insuredType":"CANADIAN",
    //    "trip":{"startDate":"2021-11-22", "endDate":"2021-12-08",
    //    "departureProvince":"BC"}, "insuredPersons":[{ "insuredType":"CANADIAN", "homeProvince":"BC", "age":20 }]
    //    }
    commit('setLoading', true)
    let cadProvDetail = new Map([
      ['Alberta', { name: 'Alberta', code: 'AB' }],
      ['British Columbia', { name: 'British Columbia', code: 'BC' }],
      ['New Brunswick', { name: 'New Brunswick', code: 'NB' }],
      ['Newfoundland and Labrador', { name: 'Newfoundland and Labrador', code: 'NL' }],
      ['Manitoba', { name: 'Manitoba', code: 'MB' }],
      ['Nova Scotia', { name: 'Nova Scotia', code: 'NS' }],
      ['Prince Edward Island', { name: 'Prince Edward Island', code: 'PE' }],
      ['Quebec', { name: 'Quebec', code: 'QC' }],
      ['Ontario', { name: 'Ontario', code: 'ON' }],
      ['Saskatchewan', { name: 'Saskatchewan', code: 'SK' }],
      ['Alberta', { name: 'Alberta', code: 'AB' }],
    ])
    let provdetail = 'unknown'
    provdetail = cadProvDetail.get(payload.origin)
    let insuredPersons = []
    let primaryTraveller = { insuredType: 'CANADIAN', homeProvince: provdetail.code, age: payload.primaryTravellerAge }
    insuredPersons.push(primaryTraveller)
    if (payload.additionalTravelers.length > 0) {
      payload.additionalTravelers.forEach(entry => {
        let age = entry.age
        console.log(entry.age)
        console.log('###### additional traveller ', entry, age)
        insuredPersons.push({ insuredType: 'CANADIAN', homeProvince: provdetail.code, age: age })
      })
    }
    console.log('request travel quote from functions!! ', payload, ' prov code ', provdetail.code)
    let rec = {
      productLineCode: 'PR-FDM-1',
      insuredType: 'CANADIAN',
      destinationType: payload.destination,
      trip: { startDate: payload.startDate, endDate: payload.endDate, departureProvince: provdetail.code },
      insuredPersons: insuredPersons
    }
    commit('setTravelCanadaQuote', [])
    console.log('QUOTE REQUEST REC ', rec)
    let getCaTravelQuote = firebase.functions().httpsCallable('getCaTravelQuote')
    return getCaTravelQuote(rec)
      .then((data) => {
        console.log('response from TUGO CANADIAN TRAVEL api server ', data.data.response)
        let respArray = data.data.response[0]
        if (respArray !== undefined && respArray.haserror) {
          console.log('Error', respArray.message)
          commit('setError', respArray.message)
          commit('setLoading', false)
          return
        }
        let quotePrice = data.data.response.blanketTugo.availablePlanPrices
        console.log('setting with ', quotePrice)
        commit('setTravelCanadaQuote', quotePrice)
        commit('setLoading', false)

        // set loading false
      })
      .catch(
        (error) => {
          console.log(error)
          commit('setError', error)
          commit('setLoading', false)
        }
      )
  },
  async purchaseTravelInsuranceCanada ({ commit, state }, payload) {
    commit('setLoading', true)
    console.log('TRIP DETAILS ', payload.trip)
    console.log('request travel purchase from functions!! ', payload)
    let uid = firebase.auth().currentUser.uid
    console.log('UID IS ', state.user)
    let insuredPersons = ''
    insuredPersons = payload.insuredPersons
    // mr mr mr
    let tripdetails = state.travelDetails
    console.log('tripdetails are ', tripdetails)
    let cadProvDetail = new Map([
      ['Alberta', { name: 'Alberta', code: 'AB' }],
      ['British Columbia', { name: 'British Columbia', code: 'BC' }],
      ['New Brunswick', { name: 'New Brunswick', code: 'NB' }],
      ['Newfoundland and Labrador', { name: 'Newfoundland and Labrador', code: 'NL' }],
      ['Manitoba', { name: 'Manitoba', code: 'MB' }],
      ['Nova Scotia', { name: 'Nova Scotia', code: 'NS' }],
      ['Prince Edward Island', { name: 'Prince Edward Island', code: 'PE' }],
      ['Quebec', { name: 'Quebec', code: 'QC' }],
      ['Ontario', { name: 'Ontario', code: 'ON' }],
      ['Saskatchewan', { name: 'Saskatchewan', code: 'SK' }],
      ['Alberta', { name: 'Alberta', code: 'AB' }],
    ])
    let provdetail = 'unknown'
    provdetail = cadProvDetail.get(state.travelDetails.origin)
    console.log('payload to final PURCHASE request', payload, state.travelDetails)
    insuredPersons[0].homeProvince = provdetail.code
    // insuredPersons[0].addresses[0].province = provdetail.code
    console.log('buying ca travel with home prov of ', insuredPersons)

    //
    let rec = {
      userkey: firebase.auth().currentUser.uid,
      policy: {
        policyInfo: {}, // keep as empty object
        trip: payload.trip,
        insuredPersons: insuredPersons,
        payments: payload.payments
      }
    }
    console.log(JSON.stringify(rec))
    // let temprec = {
    //   userkey: '2WYh1dN0HdVGCAzIDIVkPWQ4GjI3',
    //   policy: {
    //       policyInfo: {},
    //   trip: {
    //     startDate: '2022-12-02',
    //     endDate: '2022-12-09'
    //   },
    //   insuredPersons: [
    //     {
    //       insuredType: 'CANADIAN',
    //       inHomeProvince: true,
    //       homeProvince: 'BC',
    //       firstName: 'Jack',
    //       lastName: 'Beanstalk',
    //       birthDate: '2000-02-02',
    //       phoneNumbers: [
    //         {
    //           number: '6041232413'
    //         }
    //       ],
    //       addresses: [
    //         {
    //           type: 'MAIL',
    //           addressLine1: 'P.O. Box 106, 1393 Eget Ave',
    //           city: 'VANCOUVER',
    //           country: 'CA',
    //           province: 'AB',
    //           postalCode: 'T7Y 8J0'
    //         }
    //       ],
    //       selectedPlans: [
    //         {
    //           planCode: 'PL-SMED-4'
    //         }
    //       ]
    //     }
    //   ],
    //   payments: [
    //     {
    //       transactionType: 'PURCHASE',
    //       paymentType: 'CREDITCARD',
    //       paymentMethod: 'SINGLE',
    //       currencyCode: 'CAD',
    //       amount: 18.48,
    //       creditCardNumber: '4030000010001234',
    //       creditCardType: 'VISA',
    //       cardHolder: 'Roger Wilco',
    //       cardExpirationDate: '2023-10',
    //       cardCVV: '123'
    //     }
    //   ]
    //   }

    // }
    let purchaseTravelInsuranceCa = firebase.functions().httpsCallable('purchaseTravelInsuranceCa')
    return purchaseTravelInsuranceCa(rec)
      .then((data) => {
        console.log('##### CHECK ERROR CASE ', data)

        console.log('response from TUGO CANADIAN TRAVEL api server ', data.data.response)
        let policyResponse = data.data.response
        console.log('setting with ', policyResponse)
        if (!policyResponse.haserror) {
          commit('setTravelPolicyPurchase', policyResponse)
        } else {
          let errorMessages = policyResponse.messages
          console.log('our error messages ', errorMessages)
          let displayError = ''
          for (let msg of errorMessages) {
            console.log(msg)
            displayError += '\n\n' + msg.text + '\n\n'
          }
          commit('setError', displayError)
          commit('setLoading', false)
          return
        }
        // handle business logic error here

        // commit('setPetPremiums', data.data.response)
        commit('setLoading', false)

        // set loading false
      })
      .catch((error) => {
          console.log('##### HANDLE ERROR CASE ', error)
          commit('setLoading', false)
          // console.log(error)
          // commit('setError', 'There was an error for this transaction')
          // commit('setLoading', false)
        }
      )
  },
  resetTravelCanadaError ({ commit }) {
    commit('setTravelCanadaError', '')
  },
  async resetTravelCanada ({ commit }, payload) {
    console.log('RESETTING ALL CANADIAN TRAVEL !@#!@#!@#!#')
    commit('setTravelPolicyPurchase', '')
    commit('setTravelCanadaQuote', '')
    commit('setCanadianTravelProducts', '')
    commit('setTravelDetails', '')
    commit('setTravelCoverageDetails', '')
    commit('setTravelCanadaError', '')
  },
  async loadCanadianTravelProducts ({ commit }, payload) {
    let travelProducts = []
    firebase.database().ref('canada').child('travel').child('provider').child('tugo').child('products').once('value')
      .then((data) => {
        const obj = data.val()
        for (let key in obj) {
          travelProducts.push({
            code: obj[key].code,
            description: obj[key].description,
            name: obj[key].name,
            plans: obj[key].plans,
            productLine: obj[key].productLine,
          })
        }
        commit('setCanadianTravelProducts', travelProducts)
      })
      .catch(
        (error) => {
          console.log(error)
        }
      )
  },
  setTravelDetails ({ commit, dispatch }, payload) {
    console.log('get products with options ', payload)
    commit('setTravelDetails', payload)
  },
  setUSATravelDetails ({ commit }, payload) {
    console.log('get USA products with options ', payload)
    // commit('setTravelDetails', payload)
    commit('setUSATravelDetails', payload)
  },
  async setTravelCoverageDetails ({ commit, state }, payload) {
    // lets get final pricing here
    commit('setLoading', true)
    // call for final pricing
    //
    let cadProvDetail = new Map([
      ['Alberta', { name: 'Alberta', code: 'AB' }],
      ['British Columbia', { name: 'British Columbia', code: 'BC' }],
      ['New Brunswick', { name: 'New Brunswick', code: 'NB' }],
      ['Newfoundland and Labrador', { name: 'Newfoundland and Labrador', code: 'NL' }],
      ['Manitoba', { name: 'Manitoba', code: 'MB' }],
      ['Nova Scotia', { name: 'Nova Scotia', code: 'NS' }],
      ['Prince Edward Island', { name: 'Prince Edward Island', code: 'PE' }],
      ['Quebec', { name: 'Quebec', code: 'QC' }],
      ['Ontario', { name: 'Ontario', code: 'ON' }],
      ['Saskatchewan', { name: 'Saskatchewan', code: 'SK' }],
      ['Alberta', { name: 'Alberta', code: 'AB' }],
    ])
    let provdetail = 'unknown'
    provdetail = cadProvDetail.get(state.travelDetails.origin)
    console.log('payload to final pricing request', payload, state.travelDetails)
    //
    let tripdetails = state.travelDetails
    console.log('TRIP DETAILS ', tripdetails)
    let insuredPersons = [
      {
        insuredType: 'CANADIAN',
        homeProvince: provdetail.code,
        age: tripdetails.primaryTravellerAge,
        plansToPrice: [],
        selectedPlans: [],
      }
    ]
    if (tripdetails.additionalTravelers.length > 0) {
      tripdetails.additionalTravelers.forEach(traveller => {
        insuredPersons.push({
          insuredType: 'CANADIAN',
          homeProvince: provdetail.code,
          age: traveller.age,
          plansToPrice: [],
          selectedPlans: [],
        })
      })
    }
    let selectedPlans = payload.selectedPlans
    selectedPlans.forEach((item, index) => {
      console.log('ITEM', item, 'INDEX', index)
      item.forEach(entry => {
        console.log('plan selected ', entry.planCode)
        insuredPersons[index].plansToPrice.push({
          planCode: entry.planCode,
        })
        insuredPersons[index].selectedPlans.push({
          planCode: entry.planCode,
        })
      })
    })
//     selectedPlans.forEach(entry => {
//       if (!entry === []) {
//       entry.forEach(entry => {
//       console.log('plan selected ', entry.planCode)
//       plansToPrice.push({
//         planCode: entry.planCode,
//         priceInputParameters: []
//       })
// })
// }
//     })
    let rec = {
      partnerCode: 'NAD100',
      productLineCode: 'PR-FDM-1',
      trip: {
        startDate: tripdetails.startDate,
        endDate: tripdetails.endDate,
        departureProvince: provdetail.code
      },
      insuredPersons: insuredPersons

    }
    console.log('setting insured persons ', insuredPersons)
    commit('setInsuredPersons', insuredPersons)
    console.log('sending rec for final pricing ', rec)

    let testrec = {
      productLineCode: 'PR-FDM-1',
      trip: {
        startDate: '2022-12-08',
        endDate: '2022-12-10'
      },
      insuredPersons: [
        {
          insuredType: 'CANADIAN',
          inHomeProvince: true,
          homeProvince: 'AB',
          firstName: 'First First First',
          lastName: 'Last Last',
          birthDate: '2000-01-01',
          isStudent: false,
          gender: 'MALE',
          language: 'en',
          addresses: [
            {
              type: 'MAIL',
              addressLine1: '6081 No. 3 Road',
              city: 'Richmond',
              country: 'CA',
              province: 'BC',
              postalCode: 'V6Y 2B2'
            }
          ],
          phoneNumbers: [
            {
              number: '6041112222'
            }
          ],
          selectedPlans: [
            {
              planCode: 'PL-SMED-4',
              priceInputParameters: []
            },
            {
              planCode: 'PL-STCAN-7',
              priceInputParameters: []
            }
          ],
          plansToPrice: [
            {
              planCode: 'PL-SMED-4',
              priceInputParameters: []
            },
            {
              planCode: 'PL-STCAN-7',
              priceInputParameters: []
            }
          ]
        }
      ]
    }
    let getCaTravelQuoteFinal = firebase.functions().httpsCallable('getCaTravelQuoteFinal')
    return getCaTravelQuoteFinal(rec)
      .then((data) => {
        console.log('response from TUGO CANADIAN TRAVEL FINAL api server ', data.data.response)
        let errorcondition = false
        errorcondition = data.data.response.haserror
        if (errorcondition === true) {
          console.log('handle the error ', data.data.response)
        }
        let quotePrice = data.data.response.blanketTugo
        console.log('setting final with ', quotePrice)
        // check for error condition
        // if no error
        commit('setTravelCanadaQuoteFinalPricing', quotePrice)

        // commit('setPetPremiums', data.data.response)
        commit('setLoading', false)

        // set loading false
      })
      .catch(
        (error) => {
          console.log(error)
          commit('setLoading', false)
        }
      )
  },
  setTugoMedicalSurvey ({ commit }, payload) {
    commit('setTugoMedicalSurvey', payload)
  },
  purchaseTravelCoverage ({ commit }, payload) {
    // call into firebase cloud function
    // handle response
  },
  // getpremium
  //
  // pet section
  setEligibilityDetails ({ commit }, payload) {
    commit('setEligibilityDetails', payload)
  },
  setPetDeductibe ({ commit }, payload) {
    commit('setPetDeductibe', payload)
  },
  updatePetPolicyOptions ({ commit }, payload) {
    commit('updatePetPolicyOptions', payload)
  },
  updatePet ({ commit }, payload) {
    commit('updatePet', payload)
  },
  updatePetAge ({ commit }, payload) {
    commit('updatePetAge', payload)
  },
  setPetDetails ({ commit }, payload) {
    commit('setPetDetails', payload)
  },
  getPetBillingPreviewUSA ({ commit }, payload) {
    console.log('billing prev payload', payload)
    commit('setLoading', true)
    let country = 'USA'
    if (payload.details.CountryCode === 'CAN') {
      country = 'CAN'
    }
    let rec = {
      CountryCode: country,
      StateOrProvinceCode: payload.details.StateOrProvinceCode,
      PostalCode: payload.details.PostalCode,
      Email: payload.details.Email,
      Pets: payload.pets
    }
    console.log('request billing preview from functions!! ', rec)
    let getBillingPreview = firebase.functions().httpsCallable('petbillingpreview')
    return getBillingPreview(rec)
      .then((data) => {
        console.log('billing preview response ', data)
        if ((data.data.data.haserror === undefined && data.data.data[0].haserror === true) || (!(data.data.data.haserror === undefined) && data.data.data.haserror === true)) {
          commit('setError', 'Unable to process request')
          commit('setLoading', false)
        } else {
          let billingPreview = data.data.data.trupresponse
          console.log('setting with ', billingPreview)
          commit('setPetBillingPreview', billingPreview)
          commit('setLoading', false)
        }
      })
      .catch(
        (error) => {
          console.log(error)
          commit('setError', 'Unable to process request')
          commit('setLoading', false)
        }
      )
  },
  calcPetPremiums ({ commit }, payload) {
    commit('setLoading', true)
    console.log('we are loading')
    console.log('getting pet policy details!! ')
    let rec = {
      DateOfBirth: payload.DateOfBirth,
      BreedName: payload.BreedName,
      Gender: payload.Gender,
      IsSpayedOrNeutered: payload.IsSpayedOrNeutered,
      PostalCode: payload.PostalCode,
      StateOrProvinceCode: payload.StateOrProvinceCode,
      CountryCode: payload.CountryCode,
      IsServiceAnimal: payload.IsServiceAnimal,
      IsBreedingPet: payload.Gender !== 'F' || payload.IsSpayedOrNeutered === true ? false : payload.IsBreedingPet,
      idx: payload.index
    }
    console.log('pet calc prem rec is ', rec)
    let calcPetPremiums = firebase.functions().httpsCallable('calcPetPremiums')
    return calcPetPremiums(rec)
      .then((data) => {
        console.log(data)
        if ((data.data.response.haserror === undefined && data.data.response[0].haserror === true) || (!(data.data.response.haserror === undefined) && data.data.response.haserror === true)) {
          commit('setError', 'Unable to process request')
          commit('setLoading', false)
        } else {
          let premiums = data.data.response.trupresponse
          let payload = {
            premiums: premiums,
            idx: rec.idx
          }
          console.log('prem payload', payload)
          commit('setPetPremiums', payload)
          commit('setLoading', false)
        }
      })
      .catch(
        (error) => {
          console.log(error)
          commit('setError', 'Unable to process request')
          commit('setLoading', false)
        }
      )
  },
  // getEligibility
  getPetEligibility ({ commit }, payload) {
    commit('setLoading', true)
    let rec = {
      PetName: payload.PetName,
      StateOrProvinceCode: payload.StateOrProvinceCode,
      PostalCode: payload.PostalCode,
      CountryCode: payload.CountryCode,
      Email: payload.Email,
      BreedName: payload.BreedName
    }
    console.log(rec)
    let getEligibility = firebase.functions().httpsCallable('getEligibility')
    return getEligibility(rec)
      .then((data) => {
        console.log(data)
        if ((data.data.response.haserror === undefined && data.data.response[0].haserror === true) || (!(data.data.response.haserror === undefined) && data.data.response.haserror === true)) {
          commit('setError', 'Unable to process request')
          commit('setLoading', false)
        } else if (data.data.response.haserror === false) {
          let resp = data.data.response.trupresponse
          console.log('trupanion eligibility response', resp)
          if (resp.IsEligible === true) {
            console.log('ELIGIBILITY TRUE')
            commit('setEligibility', true)
          } else if (resp.IsEligible === false) {
            commit('setPetNotEligible', true)
          }
          commit('setEligibilityData', resp)
          commit('setLoading', false)
        }
      })
      .catch(
        (error) => {
          console.log(error)
          commit('setError', 'Unable to process request')
          commit('setLoading', false)
        }
      )
  },
  // Set Trupanion Customer Details
  setPetInfoData ({ commit }, payload) {
    commit('setPetInfoData', payload)
  },
  setPetPlanData ({ commit }, payload) {
    commit('setPetPlanData', payload)
  },
  resetPets ({ commit }) {
    commit('resetPets')
  },
  addPets ({ commit }, payload) {
    commit('setLoading', true)
    commit('addPets', payload)
    commit('setLoading', false)
  },
  removePet ({ commit }, payload) {
    commit('removePet', payload)
  },
  // purchasePetPolicy
  purchasePetPolicy ({ commit }, payload) {
    commit('setLoading', true)
    let rec = {
      userkey: firebase.auth().currentUser.uid,
      isExistingPolicyholder: payload.isExistingPolicyholder,
      PaymentMethodId: payload.PaymentMethodId,
      Email: payload.Email,
      Pets: payload.Pets,
      FirstName: payload.FirstName,
      LastName: payload.LastName,
      Address: payload.Address,
      Address2: '',
      City: payload.City,
      StateOrProvinceCode: payload.StateOrProvinceCode,
      CountryCode: payload.CountryCode,
      PostalCode: payload.PostalCode,
      Phone: payload.Phone,
      Prices: payload.Prices,
    }
    let purchasePolicy = firebase.functions().httpsCallable('purchasePetPolicy')
    return purchasePolicy(rec)
      .then((data) => {
        if ((data.data.response.haserror === undefined && data.data.response[0].haserror === true) || (!(data.data.response.haserror === undefined) && data.data.response.haserror === true)) {
          commit('setError', 'Unable to process request')
          commit('setLoading', false)
        } else {
          console.log('trupanion purchase response', data.data.response.trupresponse)
          let resp = {
            data: data.data.response.trupresponse,
            firstname: rec.FirstName,
            lastname: rec.LastName,
            prices: rec.Prices,
            pets: rec.Pets
          }
          commit('setPetPolicy', resp)
          commit('setLoading', false)
        }
        // set loading false
      })
      .catch(
        (error) => {
          console.log(error)
          commit('setError', 'Unable to process request')
          commit('setLoading', false)
        }
      )
  },
  // purchasePetPolicyHasBilling
  purchasePetPolicyHasBilling ({ commit }, payload) {
    commit('setLoading', true)
    let rec = {
      isExistingPolicyholder: payload.isExistingPolicyholder,
      Email: payload.Email,
      Pets: payload.Pets,
      FirstName: payload.FirstName,
      LastName: payload.LastName,
      Address: payload.Address,
      Address2: '',
      City: payload.City,
      StateOrProvinceCode: payload.StateOrProvinceCode,
      CountryCode: payload.CountryCode,
      PostalCode: payload.PostalCode,
      Phone: payload.Phone,
      Prices: payload.Prices,
    }
    let purchasePolicy = firebase.functions().httpsCallable('purchasePetPolicyHasBilling')
    return purchasePolicy(rec)
      .then((data) => {
        if ((data.data.response.haserror === undefined && data.data.response[0].haserror === true) || (!(data.data.response.haserror === undefined) && data.data.response.haserror === true)) {
          commit('setError', 'Unable to process request')
          commit('setLoading', false)
        } else {
          console.log('trupanion purchase response', data.data.response.trupresponse)
          let resp = {
            data: data.data.response.trupresponse,
            firstname: rec.FirstName,
            lastname: rec.LastName,
            prices: rec.Prices,
            pets: rec.Pets
          }
          commit('setPetPolicy', resp)
          commit('setLoading', false)
        }
      })
      .catch(
        (error) => {
          console.log(error)
          commit('setError', 'Unable to process request')
          commit('setLoading', false)
        }
      )
  },
  // enrollment
  setPetCoverageDetails ({ commit }, payload) {
    commit('setPetCoverageDetails', payload)
  },
  // Auto Details
  setAutoDetails ({ commit }, payload) {
    commit('setAutoDetails', payload)
  },
  purchasePetCoverage ({ commit }, payload) {
    // call into firebase cloud function
    // handle response
  },
  loadPetBreeds ({ commit }) {
    commit('setLoading', true)

    console.log('request breeds from functions!! ')

    let getBreeds = firebase.functions().httpsCallable('getbreeds')
    getBreeds()
      .then((resp) => {
        console.log('response from trupanion api server breeds ', resp.data.data)
        let breeds = resp.data.data
        // sort alpha by name
        breeds = breeds.sort(function (a, b) {
          if (a.Name < b.Name) {
            return -1
          }
          if (a.Name > b.Name) {
            return 1
          }
          return 0
        })
        commit('setPetBreeds', resp.data.data)
        commit('setLoading', false)

        // set loading false
      })
      .catch(
        (error) => {
          console.log(error)
          commit('setError', 'Unable to process request')
          commit('setLoading', false)
        }
      )
  },
  loadPetAges ({ commit }) {
    commit('setLoading', true)

    console.log('request ages from functions!! ')

    let getAges = firebase.functions().httpsCallable('getages')
    getAges()
      .then((resp) => {
        console.log('response from trupanion api server ages ', resp.data.data)
        commit('setPetAges', resp.data.data)
        commit('setLoading', false)
        // set loading false
      })
      .catch(
        (error) => {
          console.log(error)
          commit('setError', 'Unable to process request')
          commit('setLoading', false)
        }
      )
  },
  getvetsbyzip ({ commit }, payload) {

  },
  getpetpolicyterms ({ commit }) {

  },
  // auto section
  // renters section
  // term life section
  async requestpolicyproviders ({ commit }, payload) {
    commit('setLoading', true)
    // set loading flag
    let rec = {
      policyvalue: payload.policyvalue,
      location: payload.location,
      replacement: payload.replacement,
      userage: payload.userage
    }
    console.log('provider request data ', rec)
    commit('setProviderRecommendations', 'Symetra')
    commit('setLoading', false)
  },
  clearDetails ({ commit, state }) {
    state.bmi = ''
    state.age = ''
    state.nic = ''
    state.income = ''
    state.gender = ''
    state.smoker = ''
    state.usertermlifeclass = ''
    state.termLifeRates = []
    console.log('all clear')
  },
  requestSymetraClass ({ commit, state }, payload) {
    console.log('sending to local symetra class ', payload)
    let smoker

    let weight = payload.weight
    let height = parseInt(payload.feet * 12) + parseInt(payload.inches)// inches
    let bmi = [(weight) / (height * height)] * 703
    if (payload.smoker === 'Y') {
      smoker = true
    }
    if (payload.smoker === 'N') {
      smoker = false
    }
    const min_bmi = 17
    const max_bmi = 37.01

    // api driven
    const min_super_preferred = 18.5
    const max_super_preferred = 27.01
    const max_preferred_nonnic = 28.51
    const max_std = 33.01
    const max_std_nic = 33.01

    // no need for api, agent driven here
    const max_ruw = 34.01
    const max_full_uw = 37.01
    // class the edge cases of too thin or too large
    if (bmi < min_bmi || bmi > max_bmi) {
      commit('setusertermlifeclass', 'NonSmoker')
      return
    }
    // class smokers first
    if (smoker) {
      if (bmi >= min_bmi && bmi <= max_preferred_nonnic) {
        console.log('local class ', 'Pref Nic')
        // class is Pref Smoker
        commit('setusertermlifeclass', 'Pref Nic')
        return
      } else if (bmi >= min_bmi && bmi <= max_std_nic) {
        console.log('local class ', 'std Nic')

        // class Smoker
        commit('setusertermlifeclass', 'Standard Nic')
        return
      } else {
        console.log('local class ', 'std Nic')

        commit('setusertermlifeclass', 'Standard Nic')
        return

        // fullruw or we just say nope?
      }
    }
    if (!smoker) {
      if (bmi > min_super_preferred && bmi <= max_super_preferred) {
        console.log('local class ', 'Super Pref')

        commit('setusertermlifeclass', 'Super Pref')
      } else if (bmi <= max_preferred_nonnic) {
        console.log('local class ', 'Preferred')
        commit('setusertermlifeclass', 'Preferred')
        // pref
      } else if (bmi <= max_std) {
        console.log('local class ', 'NonSmoker')
        commit('setusertermlifeclass', 'NonSmoker')
      } else {
        console.log('local class ', 'NonSmoker')
        commit('setusertermlifeclass', 'NonSmoker')
      }
    }
  },
  requesttermlifeclass ({ commit, state }, payload) {
    commit('setLoading', true)
    console.log('REQUESTING TERM LIFE CLASS ', payload)
    let weight = payload.weight
    let height = parseInt(payload.feet * 12) + parseInt(payload.inches)// inches
    let bmi = [(weight) / (height * height)] * 703
    console.log('calc bmi in request ', bmi)
    let rec = {
      bmi: bmi,
      smoker: payload.smoker
    }
    console.log('BMI ', payload.bmi, ' smoker ', payload.smoker)
    let requestclass = firebase.functions().httpsCallable('requesttermlifeclass')
    requestclass(rec)
      .then((data) => {
        commit('setusertermlifeclass', data.data.response)
        commit('setLoading', false)

        // set loading false
      })
      .catch(
        (error) => {
          console.log(error)
          commit('setLoading', false)
        }
      )
  },
  loaduserpoliciesUSATravel ({ commit, state }, payload) {
    console.log('GET USA TRAVEL')
    const policies = []
    let userkey = firebase.auth().currentUser.uid
    console.log(userkey)
    firebase.database().ref('users').child(userkey).child('policies').child('usatravel').once('value')
      .then((data) => {
        const obj = data.val()
        for (let key in obj) {
          let cov = Object.values(obj[key].coverages)
          console.log('travel usa db ', obj[key])
          let departdate = ''
          let returndate = ''
          if (obj[key].departdate) {
            departdate = obj[key].departdate
          }
          if (obj[key].returndate) {
            returndate = obj[key].returndate
          }
          policies.push({
            carrier: 'Generali USA Travel',
            totalPrice: numeral(obj[key].price[0]).format('$0.00'),
            departdate: departdate,
            returndate: returndate,
            coverages: Object.values(cov[0].coverage),
            product: obj[key].product[0],
            policynumber: obj[key].policynumber[0],
            created: obj[key].created,
            key: key
          })
        }
        console.log('travel policies ', policies)
        commit('setUSATravelPolicies', policies.reverse())
      })
      .catch(
        (error) => {
          console.log(error)
        }
      )
  },
  loaduserpoliciesTrupanion ({ commit }) {
    const policies = []
    let userkey = firebase.auth().currentUser.uid
    firebase.database().ref('users').child(userkey).child('policies').child('pet').once('value')
      .then((data) => {
        const obj = data.val()
        console.log('pet obj ', obj)
        for (let key in obj) {
          policies.push({
            CountryCode: obj[key].CountryCode,
            Email: obj[key].Email,
            FirstName: obj[key].FirstName,
            Id: obj[key].Id,
            LastName: obj[key].LastName,
            Link: obj[key].Link,
            Pets: obj[key].Pets,
            PolicyId: obj[key].PolicyId,
            PolicyNumber: obj[key].PolicyNumber,
            PostalCode: obj[key].PostalCode,
            ReferenceNumber: obj[key].ReferenceNumber,
            StateOrProvinceCode: obj[key].StateOrProvinceCode,
            created: obj[key].created,
            key: key
          })
        }
        console.log('pet policies ', policies)
        commit('setTrupanionPolicies', policies)
      })
      .catch(
        (error) => {
          console.log(error)
        }
      )
  },
  loaduserpoliciesCanadianTravel ({ commit, state }, payload) {
    const policies = []
    let userkey = firebase.auth().currentUser.uid
    firebase.database().ref('users').child(userkey).child('policies').child('canadatravel').once('value')
      .then((data) => {
        const obj = data.val()
        for (let key in obj) {
          policies.push({
            totalPrice: obj[key].totalPrice,
            trip: obj[key].trip,
            policyvalue: obj[key].policyvalue,
            policyinfo: obj[key].policyInfo,
            uid: obj[key].uid,
            payments: obj[key].payments,
            key: key
          })
        }
        console.log('tugo travel policies ', policies)
        commit('setCanadianTravelPolicies', policies)
      })
      .catch(
        (error) => {
          console.log(error)
        }
      )
  },
  loaduserpolicies ({ commit, state }, payload) {
    const policies = []
    let userkey = firebase.auth().currentUser.uid
    firebase.database().ref('users').child(userkey).child('policies').child('termlife').once('value')
      .then((data) => {
        const obj = data.val()
        for (let key in obj) {
          policies.push({
            monthlypremium: obj[key].monthlypremium,
            policytype: obj[key].policytype,
            policyvalue: obj[key].policyvalue,
            provider: obj[key].provider,
            termlength: obj[key].termlength,
            timestamp: obj[key].timestamp,
            key: key
          })
        }
        // console.log('policies ', policies)
        commit('setLoadedPolicies', policies)
      })
      .catch(
        (error) => {
          console.log(error)
        }
      )
  },
  validateMobile ({ commit }, payload) {
    commit('setLoading', true)
    commit('setMobileStatus', false)

    let mobilepohone = payload
    let validatemobileCloud = firebase.functions().httpsCallable('validatemobile')
    validatemobileCloud(mobilepohone)
      .then((data) => {
        let mobileresponse = data.data.score.response
        if (mobileresponse.status === 'OK') {
          if (mobileresponse.results[0]) {
            let info = mobileresponse.results[0]
            if (info.active === 'YES') {
              console.log('Its a good number')
              commit('setMobileStatus', true)
            } else {
              console.log('rejected number')
              commit('setMobileStatus', true)
            }
          }
        } else {
          commit('setMobileStatus', true)
          console.log('error returned on validate mobile request', mobileresponse.status)
        }
        commit('setLoading', false)
      })
      .catch(
        (error) => {
          console.log(error)
          commit('setMobileStatus', false)
          commit('setLoading', false)
        }
      )
  },
  validateEmail ({ commit }, payload) {
    commit('setLoading', true)
    commit('setEmailStatus', false)
    let mobilepohone = payload
    let validatemobileCloud = firebase.functions().httpsCallable('validatemailaccount')
    validatemobileCloud(mobilepohone)
      .then((data) => {
        let emailresponse = data.data.score.response
        if (emailresponse.status === 'OK') {
          if (emailresponse.results[0]) {
            let info = emailresponse.results[0]
            console.log('email info ', info)
            if (info.valid === 'YES') {
              console.log('Its a good email')
              commit('setEmailStatus', true)
            } else {
              console.log('rejected email')
              commit('setEmailStatus', true)
            }
          }
        } else {
          commit('setEmailStatus', true)
          console.log('error returned on validate email request', emailresponse.status)
        }
        commit('setLoading', false)
      })
      .catch(
        (error) => {
          console.log(error)
          commit('setMobileStatus', false)
          commit('setLoading', false)
        }
      )
  },
  createtermlifepolicy ({ commit, state }, payload) {
    let userkey = firebase.auth().currentUser.uid
    const policy = {
      policytype: 'Termlife',
      termlength: payload.termlength,
      policyvalue: payload.policyvalue,
      provider: payload.provider,
      monthlypremium: payload.monthlypremium,
      timestamp: Date.now()
    }
    console.log('make this policy ', policy)
    let key
    firebase.database().ref('users').child(userkey).child('policies').child('termlife').push(policy)
      .then((data) => {
        key = data.key
        return key
      })
      .catch((error) => {
        console.log(error)
      })
  },
  createtermlifeweblead ({ commit, state }, payload) {
    let userkey = firebase.auth().currentUser.uid
    const weblead = {
      user: state.user,
      policytype: 'termlife',
      survey: payload.survey,
      options: payload.options,
      timestamp: Date.now()
    }
    let day = moment().format('YYYY-MM-DD')
    let key
    firebase.database().ref('webleads').child(day).push(weblead)
      .then((data) => {
        key = data.key
        return key
      })
      .catch((error) => {
        console.log(error)
      })
  },
  createalert ({ commit }, payload) {
    const alert = {
      label: payload.label,
      event_type: payload.event_type,
      method: payload.method,
      destination: payload.destination
    }
    let key
    firebase.database().ref('ALERTS').push(alert)
      .then((data) => {
        key = data.key
        return key
      })
      .catch((error) => {
        console.log(error)
      })
  },
  updateAlert ({ commit, getters }, payload) {
    const alert = {
      label: payload.label,
      event_type: payload.event_type,
      method: payload.method,
      destination: payload.destination
    }
    firebase.database().ref('ALERTS').child(payload.key).update(alert)
      .then(() => {
        // console.log('we updated alert ', payload.crypto_idnum)
      })
      .catch((error) => {
        console.log(error)
      })
    // Reach out to firebase and store it
  },
  removealert ({ commit }, payload) {
    const key = payload.key
    commit('setLoading', true)
    firebase.database().ref('ALERTS').child(key).remove()
      .catch((error) => {
        console.log(error)
        commit('setLoading', false)
      })
    commit('setLoading', false)
  },
  loadquote ({ commit, state }, payload) {
    const userkey = payload.userkey
    const quotekey = payload.quotekey
    let quote = ''
    firebase.database().ref('users').child(firebase.auth().currentUser.uid).child('policies').child('termlife').child(quotekey).once('value')
      .then((data) => {
        const obj = data.val()
        quote = obj
        commit('setLoadedQuote', quote)
      })
      .catch(
        (error) => {
          console.log(error)
        }
      )
  },
  loadtermlifequestions ({ commit }) {
    const questions = []
    firebase.database().ref('surveys').child('termlife').child('en').once('value')
      .then((data) => {
        const obj = data.val()
        for (let key in obj) {
          questions.push({
            language: 'en',
            label: obj[key].label,
            question: obj[key].question,
            key: key
          })
        }
        commit('setLoadedTermLifeQuestions', questions)
      })
      .catch(
        (error) => {
          console.log(error)
        }
      )
  },
  async loadSymetraOccupations ({ commit }) {
    let occupations = []
    firebase.database().ref('ACCORD').child('occupations').once('value')
      .then((data) => {
        const obj = data.val()
        for (let key in obj) {
          occupations.push({
            occupation: obj[key].occupation,
            key: key
          })
        }
        console.log('occupations ', occupations.length, occupations[1])
        // add state variable, add mutation, add getter
        commit('setLoadedSymetraOccupations', occupations)
      })
      .catch(
        (error) => {
          console.log(error)
        }
      )
  },
  async loadSymetra_ICC20LA1 ({ commit }, payload) {
    let quotekey = payload.quotekey
    commit('setSymetraICC20LA1', '') // reset
    // filename ICC20LA1_mitchell_rankin.pdf
    const storageRef = firebase.storage().ref().child('policies').child('termlife').child(quotekey).child('39_symetra_ICC20LA1.pdf')

    let pdfItem = await storageRef.getDownloadURL()
    console.log('our pdf item is LA1 ', pdfItem)
    commit('setSymetraICC20LA1', pdfItem) // reset
  },
  async loadSymetra_ICC20LA2 ({ commit }, payload) {
    let quotekey = payload.quotekey

    commit('setSymetraICC20LA2', '') // reset
    // filename ICC20LA1_mitchell_rankin.pdf
    const storageRef = firebase.storage().ref().child('policies').child('termlife').child(quotekey).child('39_ICC20LA2Questions_filled.pdf')

    let pdfItem = await storageRef.getDownloadURL()
    console.log('our pdf item is LA2 ', pdfItem)
    commit('setSymetraICC20LA2', pdfItem) // reset
  },
  async loadSymetra_ICC20LA3 ({ commit }, payload) {
    let quotekey = payload.quotekey

    commit('setSymetraICC20LA3', '') // reset
    // filename ICC20LA1_mitchell_rankin.pdf
    const storageRef = firebase.storage().ref().child('policies').child('termlife').child(quotekey).child('39_symetra_ICC20LA3.pdf')

    let pdfItem = await storageRef.getDownloadURL()
    console.log('our pdf item is LA3 ', pdfItem)
    commit('setSymetraICC20LA3', pdfItem) // reset
  },
  async loadSymetra_LU820 ({ commit }, payload) {
    commit('setSymetraLU820', '') // reset
    let quotekey = payload.quotekey
    // filename ICC20LA1_mitchell_rankin.pdf
    const storageRef = firebase.storage().ref().child('policies').child('termlife').child(quotekey).child('39_symetra_LU820.pdf')

    let pdfItem = await storageRef.getDownloadURL()
    console.log('our pdf item is LA3 ', pdfItem)
    commit('setSymetraLU820', pdfItem) // reset
  },
  async getuserlocation ({ commit }) {
    console.log('Fire getuserlocation')
    // https://api.ipify.org?format=json
    let prodpath = 'https://ipinfo.io/json?token=9a599a2f7bd49e'
    await axios
      .get(prodpath)
      .then((response) => {
        commit('setPageLoaded', true)
        console.log('Ipinfo data', response)
        if (response?.data?.country === 'CA') {
          commit('setWebsiteLocation', 'CA')
        } else {
          commit('setWebsiteLocation', '')
        }
      })
      .catch(
        (error) => {
          console.log('error getting geo location', error)
          commit('setPageLoaded', true)
          commit('setPageLoaded', true)
        }
      )
  },

  async ipinfoFunc ({ commit }, ipHere) {
    await ipinfo.lookupIp(ipHere).then((response) => {
      console.log('location ', response)
      if (response.countryCode === 'CA') {
        console.log('USER IS IN CANADA')
        commit('setWebsiteLocation', 'CA')
      } else {
        console.log('USER IS NOT CANADA SO USE USA')
        commit('setWebsiteLocation', 'US')
      }
    })
  },

  async loadSymetraNotices ({ commit }, payload) {
    commit('setSymetraPdfs', []) // reset
    commit('setnoticesloading', true)
    console.log('state payload ', payload)
    let usa_state = '39states'
    if (usa_state === 'PA') {
      usa_state = 'PA'
    }
    if (usa_state === 'CA') {
      usa_state = 'CA'
    }
    if (usa_state === 'DE') {
      usa_state = 'DE'
    }
    if (usa_state === 'FL') {
      usa_state = 'FL'
    }
    if (usa_state === 'GA') {
      usa_state = 'GA'
    }
    if (usa_state === 'IL') {
      usa_state = 'IL'
    }
    if (usa_state === 'KY') {
      usa_state = 'KY'
    }
    if (usa_state === 'ME') {
      usa_state = 'ME'
    }
    if (usa_state === 'ND') {
      usa_state = 'ND'
    }
    if (usa_state === 'SD') {
      usa_state = 'SD'
    }
    if (usa_state === 'WA') {
      usa_state = 'WA'
    }

    let pdfUrls = []
    // convert to promise all!!!
    let test = '39states'
    console.log('##### we would use ', usa_state, test)
    // gs://blanket-development.appspot.com/policies/afficiency/ARC2072579890/905.pdf
    const storageRef = await firebase.storage().ref().child('policies').child('afficiency').child('ARC1009910609')
    let listTask = await storageRef.listAll().then((results) => {
      console.log('results ', results)
      console.log('storage list item ', results.items)
      let sorted = results.items.sort(function (a, b) {
        return a - b
      })
      let promises = results.items.map((pdfItem) => {
        return pdfItem.getDownloadURL()
      })
      Promise.all(promises).then(urls => {
        pdfUrls = urls
        console.log('we have our list of urls ', pdfUrls)
        // commit('setSymetraPdfs', pdfUrls)
        // commit('setnoticesloading', false)
      })
    })
      .catch(
        (error) => {
          console.log('error getting pdfs', error)
          commit('setnoticesloading', false)
        }
      )
  },
  async loadSymetraRenderedDocuments ({ commit, dispatch }, payload) {
    console.log('we are loading the rendered documents now ')
    commit('setSymetraPdfs', []) // reset
    commit('setRenderedApplicationsLoading', true)
    console.log('quotekey ', payload)
    let quotekey = payload
    let pdfUrls = []
    // convert to promise all!!!
    const storageRef = await firebase.storage().ref().child('policies').child('termlife').child(quotekey)
    let listTask = await storageRef.listAll().then((results) => {
      // console.log('storage list item ', results.items)
      let sorted = results.items.sort(function (a, b) {
        return a - b
      })
      let promises = results.items.map((pdfItem) => {
        return pdfItem.getDownloadURL()
      })
      Promise.all(promises).then(urls => {
        pdfUrls = urls
        console.log('we have our list of urls from this quote ', pdfUrls)
        // commit('setSymetraPdfs', pdfUrls)
        console.log('setting render time false')
        dispatch('loadSymetra_ICC20LA1', { quotekey: quotekey })
        dispatch('loadSymetra_ICC20LA2', { quotekey: quotekey })
        dispatch('loadSymetra_ICC20LA3', { quotekey: quotekey })
        dispatch('loadSymetra_LU820', { quotekey: quotekey })
        commit('setRenderedApplicationsLoading', false)
        commit('setReadyforSignature', true)
      })
    })
      .catch(
        (error) => {
          console.log('error getting pdfs', error)
          commit('setRenderedApplicationsLoading', false)
          commit('setReadyforSignature', false)
        }
      )
  },
  beginSymetraDocumentProcess ({ commit }) {
    commit('setReadyforSignature', false)
  },
  async renderSymetraPDFRecords ({ commit, dispatch }, payload) {
    // generatePdfsAndSendToStorage
    commit('setCompletedSymetraPDFApplication', true)
    commit('setReadyforSignature', false)
    console.log('payload pdf generate is ', payload)
    let userkey = firebase.auth().currentUser.uid// current user
    let quotekey = payload.quotekey
    let myuser = {
      state: 'AL'
    }
    await firebase.database().ref('users').child(firebase.auth().currentUser.uid).update(myuser)
    console.log('state updated in profile ', myuser)
    let rec = {
      quotekey: payload.quotekey,
      interview: payload.healthinterview,
      owner: payload.owner,
      hobbies: payload.hobbies,
      primaryBeneficiaries: payload.primaryBeneficiaries,
      healthcareprovider: payload.healthcareprovider,
      HEALTH_FOLLOWUP: payload.HEALTH_FOLLOWUP,
      adb: payload.adb,
      adbAmount: payload.adbAmount,
      wop: payload.wop,
      ctr: payload.ctr,
      ctr_children: payload.ctr_children
    }
    // update interivew data
    await firebase.database().ref('users').child(userkey).child('policies').child('termlife').child(quotekey).update(rec)

    // update hobbies
    // update benes

    let Docrec = {
      userkey: userkey,
      quotekey: quotekey
    }
    let generatePdfs = firebase.functions().httpsCallable('generatePdfsAndSendToStorage')
    generatePdfs(Docrec)
      .then((data) => {
        let recm = data.data.response
        console.log('symetra pdfs rendered responded')
        dispatch('loadSymetraRenderedDocuments', quotekey)
        // now call to get the pdf urls here in actions
        commit('setCompletedSymetraPDFApplication', false)
      })
      .catch(
        (error) => {
          console.log(error)
          commit('setCompletedSymetraPDFApplication', false)
        }
      )
  },
  async loadSymetraCompletedClientForms ({ commit }, payload) {
    commit('setSymetraCCompletedClientPdfs', []) // reset
    commit('setCompletedClientloading', true)
    console.log('state payload ', payload)
    let clientkey = firebase.auth().currentUser.uid // current logged in user
    let quotekey = payload.quotekey
    let pdfUrls = []
    // convert to promise all!!!
    let test = '39states'
    const storageRef = await firebase.storage().ref().child('symetra').child(test)
    let listTask = await storageRef.listAll().then((results) => {
      // console.log('storage list item ', results.items)
      let sorted = results.items.sort(function (a, b) {
        return a - b
      })
      let promises = results.items.map((pdfItem) => {
        return pdfItem.getDownloadURL()
      })
      Promise.all(promises).then(urls => {
        pdfUrls = urls
        console.log('we have our list of urls of the completed application ', pdfUrls)
        commit('setSymetraCCompletedClientPdfs', pdfUrls)
        commit('setCompletedClientloading', true)
      })
    })
      .catch(
        (error) => {
          console.log('error getting pdfs', error)
          commit('setCompletedClientloading', true)
        }
      )
  },
  loadSymetraAppQuestions ({ commit }) {
    const questions = []
    firebase.database().ref('symetra').child('termlife').child('application').child('en').once('value')
      .then((data) => {
        const obj = data.val()
        for (let key in obj) {
          questions.push({
            language: 'en',
            label: obj[key].label,
            question: obj[key].question,
            rank: obj[key].rank,
            ApiId: obj[key].ApiId,
            key: key
          })
        }
        commit('setLoadedSymetraAppQuestions', questions)
      })
      .catch(
        (error) => {
          console.log(error)
        }
      )
  },
  createSymetraAppQuestion ({ commit }, payload) {
    const entry = {
      language: 'en',
      label: payload.label,
      ApiId: payload.ApiId,
      rank: payload.rank,
      question: payload.question,
      key: '',
    }
    let key
    firebase.database().ref('symetra').child('termlife').child('application').child('en').push(entry)
      .then((data) => {
        key = data.key
        return key
      })
      .catch((error) => {
        console.log(error)
      })
  },
  updateSymetraAppQuestion ({ commit, getters }, payload) {
    const rel = {
      language: 'en',
      label: payload.label,
      ApiId: payload.ApiId,
      rank: payload.rank,
      question: payload.question,
      key: payload.key
    }

    firebase.database().ref('symetra').child('termlife').child('application').child('en').child(rel.key).update(rel)
      .then(() => {
        // console.log('we updated site ', payload.key)
      })
      .catch((error) => {
        console.log(error)
      })
    // Reach out to firebase and store it
  },
  saveTermlifeAnicoApplication ({ commit, state }, payload) {
    // we marked it completed, so we don't allow edits
    commit('setLoading', true)
    let quotekey = payload.quotekey
    let rec = {
      status: 'processing'
    }
    firebase.database().ref('users').child(firebase.auth().currentUser.uid).child('policies').child('termlife').child(quotekey).update(rec)
      .then((data) => {
        console.log('recorded your application!')
      })
      .catch(
        (error) => {
          console.log(error)
        }
      )
    // send the reference of the record to our function to submission to the process server.
    // let the record be pulled by that process, do NOT send it the data.
    let completedApp = {
      userkey: firebase.auth().currentUser.uid,
      quotekey: quotekey
    }
    let submitAnicoTermLifeApplication = firebase.functions().httpsCallable('submitAnicoTermLifeApplication')
    submitAnicoTermLifeApplication(completedApp)
      .then((data) => {
        let recm = data.data.response
        commit('setLoading', false)
      })
      .catch(
        (error) => {
          console.log(error)
          commit('setLoading', false)
        }
      )
  },
  loadAnicoTermLifeAnswers ({ commit }, payload) {
    let interviewkey = payload
  },
  updateAnicoTermLifeInterview ({ commit, state }, payload) {
    let quotekey = payload.quotekey
    let questionkey = payload.questionkey
    let answer = payload.answer
    // update the timestamp also!
    firebase.database().ref('users').child(firebase.auth().currentUser.uid).child('policies').child('termlife').child(quotekey).child('interview').child(questionkey).set(answer)
      .catch(err => {
        console.log(err)
      })
  },
  loadAnicoAppQuestions ({ commit }) {
    const questions = []
    firebase.database().ref('anico').child('termlife').child('application').child('en').once('value')
      .then((data) => {
        const obj = data.val()
        for (let key in obj) {
          questions.push({
            language: 'en',
            label: obj[key].label,
            question: obj[key].question,
            answer: '',
            key: key
          })
        }
        commit('setDefaultAnicoTermLifeInterview', questions)
        commit('setLoadedAnicoAppQuestions', questions)
      })
      .catch(
        (error) => {
          console.log(error)
        }
      )
  },
  createAnicoAppQuestion ({ commit }, payload) {
    const entry = {
      language: 'en',
      label: payload.label,
      question: payload.question,
      key: '',
    }
    let key
    firebase.database().ref('anico').child('termlife').child('application').child('en').push(entry)
      .then((data) => {
        key = data.key
        return key
      })
      .catch((error) => {
        console.log(error)
      })
  },
  updateAnicoAppQuestion ({ commit, getters }, payload) {
    const rel = {
      language: 'en',
      label: payload.label,
      question: payload.question,
      key: payload.key
    }

    firebase.database().ref('anico').child('termlife').child('application').child('en').child(rel.key).update(rel)
      .then(() => {
        // console.log('we updated site ', payload.key)
      })
      .catch((error) => {
        console.log(error)
      })
    // Reach out to firebase and store it
  },
  createtermlifequstion ({ commit }, payload) {
    const entry = {
      language: 'en',
      label: payload.label,
      question: payload.question,
      key: '',
    }
    let key
    firebase.database().ref('surveys').child('termlife').child('en').push(entry)
      .then((data) => {
        key = data.key
        return key
      })
      .catch((error) => {
        console.log(error)
      })
  },
  updatetermlifequestion ({ commit, getters }, payload) {
    const rel = {
      language: 'en',
      label: payload.label,
      question: payload.question,
      key: payload.key
    }

    firebase.database().ref('surveys').child('termlife').child('en').child(rel.key).update(rel)
      .then(() => {
        // console.log('we updated site ', payload.key)
      })
      .catch((error) => {
        console.log(error)
      })
    // Reach out to firebase and store it
  },
  async symetraInterviewUpdate ({ commit }, payload) {
    const rec = {
      qId: payload.qId,
      caseId: payload.caseId,
      answer: payload.answer
    }
    console.log('posting to adapter ')
    let prodpath = 'http://localhost:3000/apply/interview/question/'
    await axios
      .post(prodpath,
        rec, {
          headers: {
            // 'Content-Type': 'application/json',
            // 'API-key': '992394823984728934792378',
            // 'API-Username': 'alphauser',
            // 'API-Password': 'batman'
          },
        })
      .then((response) => {
        console.log('symetra adapter response ', response.status, response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  checkpostcode ({ commit }, payload) {
    console.log('looking zip code data ')
    if (payload.length !== 5) {
      return
    }
    let loc = zipcodes.lookup(payload)
    console.log(loc)
    let rec
    if (loc.country === 'US') {
      rec = {
        country_code: 'US',
        state_code: loc.state
      }
    }

    commit('setPostcode', rec)
  },
  async updateUserProfile ({ commit }, payload) {
    await firebase.database().ref('users').child(firebase.auth().currentUser.uid).update(payload)
      .then(() => {
        // Update user in $store
        firebase.database().ref('users').child(firebase.auth().currentUser.uid).once('value')
          .then(
            data => {
              const obj = data.val()

              let feet = ''
              let inches = ''
              let height = {
                feet: feet,
                inches: inches
              }
              if (obj.height) {
                height.feet = obj.height.feet
                height.inches = obj.height.inches
              }
              const user = {
                id: payload.uid,
                alerts: obj.alerts,
                policies: obj.policies,
                height: height,
                bdays: obj.bdays,
                bmonths: obj.bmonths,
                byears: obj.byears,
                gender: obj.gender,
                heightinches: obj.heightinches,
                heightfeet: obj.heightfeet,
                weightpounds: obj.weightpounds,
                weightkilos: obj.weightkilos,
                weight: obj.weight,
                dob: obj.dob,
                ssn: obj.ssn,
                email: obj.email,
                level: obj.level,
                status: obj.status,
                firstname: obj.firstname,
                lastname: obj.lastname,
                mobile: obj.mobile,
                address: obj.address,
                state: obj.state,
                city: obj.city,
                country: obj.country,
                zipcode: obj.zipcode,
                preferredLang: obj.preferredLangupdatelang
              }
              commit('setUser', user)
            })
      })
      .catch((error) => {
        console.log('ERROER: ', error)
      })
    // Reach out to firebase and store it
  },
  async updatelang ({ commit }, payload) {
    try {
      const currentUser = firebase.auth().currentUser
      const userDataSnapshot = await firebase.database().ref('users').child(firebase.auth().currentUser.uid).once('value')
      const userData = userDataSnapshot.val()
      if ((currentUser) || (payload.updateProfile)) {
        await firebase.database().ref('users').child(firebase.auth().currentUser.uid).update({
          preferredLang: payload?.languageCode || 'en'
        })
        console.log('userData', userData)
        return
      } else {
        return null
      }
    } catch (error) {
      console.error('Error:', error)
      // Handle errors as needed
    }
  },
  removeUserProfile ({ commit, getters }, payload) {
    const key = payload.userkey
    commit('setLoading', true)
    firebase.database().ref('users').child(key).remove()
      .then(
        // console.log('removed group')
      )
      .catch((error) => {
        console.log(error)
        commit('setLoading', false)
      })
    commit('setLoading', false)
    // Reach out to firebase and store it
  },
  getcloudCompare ({ commit }, payload) {
    console.log('&&&&&&&&& cloud compare providers request', payload)
    if (payload.policyclassification === 'Super Pref') {
      payload.userclass = 'P+'
      console.log('TRANSLATE CLASS SYMETRA', payload.userclass)
    }
    if (payload.policyclassification === 'Pref Nic') {
      payload.userclass = 'Pf'
      console.log('TRANSLATE CLASS SYMETRA', payload.userclass)
    }
    if (payload.policyclassification === 'Preferred') {
      payload.userclass = 'Pf'
      console.log('TRANSLATE CLASS SYMETRA', payload.userclass)
    }
    if (payload.policyclassification === 'NonSmoker') {
      payload.userclass = 'Rg'
      console.log('TRANSLATE CLASS SYMETRA', payload.userclass)
    }
    payload.userclass = payload.policyclassification

    console.log('HEALTH CODE SENT IS ', payload.userclass)
    let cloudCompareRequest = firebase.functions().httpsCallable('cloudCompareRequest')
    cloudCompareRequest(payload)
      .then((data) => {
        // console.log('!!!!!!!!!!!!!Cloud compare policy provider data ', data)
        let resp = Object.values(data)
        console.log(resp[0].provSample.data)
        commit('setCompareList', resp[0].provSample.data)
        // set loading false
      })
      .catch(
        (error) => {
          console.log(error)
          commit('setLoading', false)
        }
      )
  },
  routepolicyrequest ({ commit }, payload) {
    // console.log('route policy contact request', payload)
    let routepolicyrequest = firebase.functions().httpsCallable('routepolicyrequest')
    routepolicyrequest(payload)
      .then((data) => {
        // console.log('policy provider data ', data.data.response)
        // set loading false
      })
      .catch(
        (error) => {
          console.log(error)
          commit('setLoading', false)
        }
      )
  },
  recordOptions ({ commit }, payload) {
    console.log('###### record options ', payload)
    const height = parseInt(payload.feet * 12) + parseInt(payload.inches)// inches
    const weight = parseInt(payload.weight) // lbs
    const age = moment().diff(payload.bday, 'years')
    const income = parseInt(payload.income)
    let nic = payload.smoker

    if (nic === 'yes') {
      nic = true
    } else {
      nic = false
    }
    const gender = payload.gender
    // [Weight (lbs) / Height (inches)²] x 703
    console.log('weight ', weight, ' height ', height, '  ')
    let bmi = [(weight) / (height * height)] * 703
    commit('setbmi', bmi)
    // calculate their rate class based on the age and BMI
    commit('applieddetails', payload)
    commit('setnic', nic)
    commit('setage', age)
    commit('setincome', income)
    commit('setgender', gender)
    // lets get their pricing table from the db right now
    // get the price for every value and year possible
  },
  checkcurrentuserkey ({ commit }) {
    // commit('setcheckinguserkey', true)

    let key = 'UNSET'
    if (firebase.auth().currentUser) {
      key = firebase.auth().currentUser.uid
      console.log(' got key ', key)
    }
    console.log('current key ', key)
    commit('setcurrentuserkey', key)
    // commit('setcheckinguserkey', false)
  },
  setUserAge ({ commit }, payload) {
    commit('setage', payload.age)
  },
  setUserNic ({ commit }, payload) {
    commit('setnic', payload.nic)
  },
  setUserGender ({ commit }, payload) {
    commit('setgender', payload.gender)
  },
  calcAnicoClass ({ commit }, payload) {
    console.log('getting anico class', payload)
    commit('setLoading', true)
    // P+

    let nonsmokerPPlusTable = [
      { totalinches: 61, minweight: 95, maxweight: 146 },
      { totalinches: 62, minweight: 98, maxweight: 151 },
      { totalinches: 63, minweight: 101, maxweight: 156 },
      { totalinches: 64, minweight: 105, maxweight: 162 },
      { totalinches: 65, minweight: 108, maxweight: 167 },
      { totalinches: 66, minweight: 111, maxweight: 172 },
      { totalinches: 67, minweight: 115, maxweight: 177 },
      { totalinches: 68, minweight: 118, maxweight: 182 },
      { totalinches: 69, minweight: 122, maxweight: 188 },
      { totalinches: 70, minweight: 125, maxweight: 193 },
      { totalinches: 71, minweight: 129, maxweight: 199 },
      { totalinches: 72, minweight: 133, maxweight: 205 },
      { totalinches: 73, minweight: 136, maxweight: 211 },
      { totalinches: 74, minweight: 140, maxweight: 217 },
      { totalinches: 75, minweight: 144, maxweight: 222 },
      { totalinches: 76, minweight: 148, maxweight: 229 },
      { totalinches: 77, minweight: 152, maxweight: 234 },
      { totalinches: 78, minweight: 156, maxweight: 241 },
      { totalinches: 79, minweight: 160, maxweight: 247 },
      { totalinches: 80, minweight: 164, maxweight: 253 },
      { totalinches: 81, minweight: 168, maxweight: 260 },
      { totalinches: 82, minweight: 172, maxweight: 266 },
    ]
    // Pf
    let nonsmokerPfTable = [
      { totalinches: 61, minweight: 95, maxweight: 159 },
      { totalinches: 62, minweight: 98, maxweight: 165 },
      { totalinches: 63, minweight: 101, maxweight: 170 },
      { totalinches: 64, minweight: 105, maxweight: 176 },
      { totalinches: 65, minweight: 108, maxweight: 181 },
      { totalinches: 66, minweight: 111, maxweight: 187 },
      { totalinches: 67, minweight: 115, maxweight: 193 },
      { totalinches: 68, minweight: 118, maxweight: 198 },
      { totalinches: 69, minweight: 122, maxweight: 204 },
      { totalinches: 70, minweight: 125, maxweight: 210 },
      { totalinches: 71, minweight: 129, maxweight: 217 },
      { totalinches: 72, minweight: 133, maxweight: 223 },
      { totalinches: 73, minweight: 136, maxweight: 229 },
      { totalinches: 74, minweight: 140, maxweight: 235 },
      { totalinches: 75, minweight: 144, maxweight: 242 },
      { totalinches: 76, minweight: 148, maxweight: 247 },
      { totalinches: 77, minweight: 152, maxweight: 255 },
      { totalinches: 78, minweight: 156, maxweight: 262 },
      { totalinches: 79, minweight: 160, maxweight: 269 },
      { totalinches: 80, minweight: 164, maxweight: 276 },
      { totalinches: 81, minweight: 168, maxweight: 283 },
      { totalinches: 82, minweight: 172, maxweight: 290 },
    ]
    // R+
    let nonsmokerRPTable = [
      { totalinches: 61, minweight: 95, maxweight: 169 },
      { totalinches: 62, minweight: 98, maxweight: 175 },
      { totalinches: 63, minweight: 101, maxweight: 181 },
      { totalinches: 64, minweight: 105, maxweight: 187 },
      { totalinches: 65, minweight: 108, maxweight: 193 },
      { totalinches: 66, minweight: 111, maxweight: 199 },
      { totalinches: 67, minweight: 115, maxweight: 205 },
      { totalinches: 68, minweight: 118, maxweight: 211 },
      { totalinches: 69, minweight: 122, maxweight: 218 },
      { totalinches: 70, minweight: 125, maxweight: 224 },
      { totalinches: 71, minweight: 129, maxweight: 230 },
      { totalinches: 72, minweight: 133, maxweight: 237 },
      { totalinches: 73, minweight: 136, maxweight: 244 },
      { totalinches: 74, minweight: 140, maxweight: 251 },
      { totalinches: 75, minweight: 144, maxweight: 258 },
      { totalinches: 76, minweight: 148, maxweight: 265 },
      { totalinches: 77, minweight: 152, maxweight: 272 },
      { totalinches: 78, minweight: 156, maxweight: 279 },
      { totalinches: 79, minweight: 160, maxweight: 286 },
      { totalinches: 80, minweight: 164, maxweight: 293 },
      { totalinches: 81, minweight: 168, maxweight: 301 },
      { totalinches: 82, minweight: 172, maxweight: 308 },
    ]
    // smoker Pf
    let smokerPfTable = [
      { totalinches: 61, minweight: 95, maxweight: 159 },
      { totalinches: 62, minweight: 98, maxweight: 165 },
      { totalinches: 63, minweight: 101, maxweight: 170 },
      { totalinches: 64, minweight: 105, maxweight: 176 },
      { totalinches: 65, minweight: 108, maxweight: 181 },
      { totalinches: 66, minweight: 111, maxweight: 187 },
      { totalinches: 67, minweight: 115, maxweight: 193 },
      { totalinches: 68, minweight: 118, maxweight: 198 },
      { totalinches: 69, minweight: 122, maxweight: 204 },
      { totalinches: 70, minweight: 125, maxweight: 210 },
      { totalinches: 71, minweight: 129, maxweight: 217 },
      { totalinches: 72, minweight: 133, maxweight: 223 },
      { totalinches: 73, minweight: 136, maxweight: 229 },
      { totalinches: 74, minweight: 140, maxweight: 235 },
      { totalinches: 75, minweight: 144, maxweight: 242 },
      { totalinches: 76, minweight: 148, maxweight: 247 },
      { totalinches: 77, minweight: 152, maxweight: 255 },
      { totalinches: 78, minweight: 156, maxweight: 262 },
      { totalinches: 79, minweight: 160, maxweight: 269 },
      { totalinches: 80, minweight: 164, maxweight: 276 },
      { totalinches: 81, minweight: 168, maxweight: 283 },
      { totalinches: 82, minweight: 172, maxweight: 290 },
    ]
    let feet = 0
    let inches = 0
    let weight = 0
    let totalinches
    let smoker
    if (!payload.smoker || !payload.inches || !payload.feet || !payload.weight) {
      console.log('NOT ENOUGH DATA to class')
      commit('setLoading', false)
      return
    }
    weight = parseInt(payload.weight)
    smoker = payload.smoker
    totalinches = parseInt(payload.inches) + (parseInt(payload.feet) * 12)
    if (smoker === 'Y') {
      // console.log('SMOKER')
      let smokercriteria = smokerPfTable.find(client => client.totalinches === totalinches)
      if (smokercriteria) {
        if (weight >= smokercriteria.minweight && weight <= smokercriteria.maxweight) {
          console.log('class Smoker Pf')
          commit('setusertermlifeclass', 'Pf')
          commit('setLoading', false)
          return
        }
      }
      // smoker Pf or Rg
      // user the smoker table
      // they were not part of the Pf table so they are Rg Smoker
      // console.log('class Smoker Rg')

      commit('setusertermlifeclass', 'Rg')
      commit('setLoading', false)
    } else {
      // console.log('NON SMOKER')
      // use the non-smoker table
      // P+ Pf R+ Rg
      // find the entry that matches total inches
      // too tall or short....Rg
      // if matched
      // must be between min and max weight
      let nonsmokerPPcriteria = nonsmokerPPlusTable.find(client => client.totalinches === totalinches)
      if (nonsmokerPPcriteria) {
        if (weight >= nonsmokerPPcriteria.minweight && weight <= nonsmokerPPcriteria.maxweight) {
          console.log('class P+')
          commit('setusertermlifeclass', 'P+')
          commit('setLoading', false)
          return
        }
      }
      let nonsmokerPfcriteria = nonsmokerPfTable.find(client => client.totalinches === totalinches)
      if (nonsmokerPfcriteria) {
        if (weight >= nonsmokerPfcriteria.minweight && weight <= nonsmokerPfcriteria.maxweight) {
          console.log('class Pf')

          commit('setusertermlifeclass', 'Pf')
          commit('setLoading', false)
          return
        }
      }
      let nonsmokerRPcriteria = nonsmokerRPTable.find(client => client.totalinches === totalinches)
      if (nonsmokerRPcriteria) {
        if (weight >= nonsmokerRPcriteria.minweight && weight <= nonsmokerRPcriteria.maxweight) {
          console.log('class R+')

          commit('setusertermlifeclass', 'R+')
          commit('setLoading', false)
          return
        }
      }
      console.log('class Rg')

      commit('setusertermlifeclass', 'Rg')
    }
  },
  calcProtClass ({ commit }, payload) {
    console.log('PROTECTIVE CLASSING', payload)
    commit('setLoading', true)
    // P+
    let nonsmokerPPlusTable = [
      { totalinches: 55, maxweight: 120 },
      { totalinches: 56, maxweight: 125 },
      { totalinches: 57, maxweight: 129 },
      { totalinches: 58, maxweight: 134 },
      { totalinches: 59, maxweight: 139 },
      { totalinches: 60, maxweight: 143 },
      { totalinches: 61, maxweight: 148 },
      { totalinches: 62, maxweight: 153 },
      { totalinches: 63, maxweight: 158 },
      { totalinches: 64, maxweight: 163 },
      { totalinches: 65, maxweight: 168 },
      { totalinches: 66, maxweight: 173 },
      { totalinches: 67, maxweight: 179 },
      { totalinches: 68, maxweight: 184 },
      { totalinches: 69, maxweight: 190 },
      { totalinches: 70, maxweight: 195 },
      { totalinches: 71, maxweight: 201 },
      { totalinches: 72, maxweight: 206 },
      { totalinches: 73, maxweight: 212 },
      { totalinches: 74, maxweight: 218 },
      { totalinches: 75, maxweight: 224 },
      { totalinches: 76, maxweight: 230 },
      { totalinches: 77, maxweight: 236 },
      { totalinches: 78, maxweight: 242 },
      { totalinches: 79, maxweight: 249 },
      { totalinches: 80, maxweight: 255 },
      { totalinches: 81, maxweight: 261 },
      { totalinches: 82, maxweight: 268 },
      { totalinches: 83, maxweight: 274 },
    ]
    // Pf
    let nonsmokerPfTable = [
      { totalinches: 55, maxweight: 129 },
      { totalinches: 56, maxweight: 134 },
      { totalinches: 57, maxweight: 139 },
      { totalinches: 58, maxweight: 144 },
      { totalinches: 59, maxweight: 149 },
      { totalinches: 60, maxweight: 154 },
      { totalinches: 61, maxweight: 159 },
      { totalinches: 62, maxweight: 164 },
      { totalinches: 63, maxweight: 169 },
      { totalinches: 64, maxweight: 175 },
      { totalinches: 65, maxweight: 180 },
      { totalinches: 66, maxweight: 186 },
      { totalinches: 67, maxweight: 192 },
      { totalinches: 68, maxweight: 197 },
      { totalinches: 69, maxweight: 203 },
      { totalinches: 70, maxweight: 209 },
      { totalinches: 71, maxweight: 215 },
      { totalinches: 72, maxweight: 221 },
      { totalinches: 73, maxweight: 227 },
      { totalinches: 74, maxweight: 234 },
      { totalinches: 75, maxweight: 240 },
      { totalinches: 76, maxweight: 246 },
      { totalinches: 77, maxweight: 253 },
      { totalinches: 78, maxweight: 260 },
      { totalinches: 79, maxweight: 266 },
      { totalinches: 80, maxweight: 273 },
      { totalinches: 81, maxweight: 280 },
      { totalinches: 82, maxweight: 287 },
      { totalinches: 83, maxweight: 294 },
    ]
    let feet = 0
    let inches = 0
    let weight = 0
    let totalinches
    let smoker
    if (payload.smoker == null || !payload.inches || !payload.feet) {
      console.log('something missing so returning! ', payload.smoker, payload.inches, payload.feet)
      commit('setLoading', false)
      return
    }
    weight = parseInt(payload.weight)
    smoker = payload.smoker
    totalinches = parseInt(payload.inches) + (parseInt(payload.feet) * 12)
    if (smoker === true) {
      // prot only has Rg for smokers
      // console.log('PROTECTIVE CLASSING Smoker Rg')
      commit('setusertermlifeclass', 'Rg')
      commit('setLoading', false)
    }
    if (smoker === false) {
      console.log('rating non-smoker')
      // use the non-smoker table
      // P+ Pf Rg
      // find the entry that matches total inches
      // too tall or short....Rg
      // if matched
      // must be between min and max weight
      let nonsmokerPPcriteria = nonsmokerPPlusTable.find(client => client.totalinches === totalinches)
      if (nonsmokerPPcriteria) {
        if (weight <= nonsmokerPPcriteria.maxweight) {
          console.log('PROTECTIVE CLASSING P+')
          commit('setusertermlifeclass', 'P+')
          commit('setLoading', false)
          return
        }
        console.log('tried path 1 but nothing')
      }
      console.log('nonsmokerPPcriteria none ', nonsmokerPPcriteria)
      let nonsmokerPfcriteria = nonsmokerPfTable.find(client => client.totalinches === totalinches)
      if (nonsmokerPfcriteria) {
        if (weight <= nonsmokerPfcriteria.maxweight) {
          console.log('PROTECTIVE CLASSING Pf')
          commit('setusertermlifeclass', 'Pf')
          commit('setLoading', false)
          return
        }
        console.log('tried path 2 but nothing')
      }
      console.log('nonsmokerPfcriteria none ', nonsmokerPfcriteria)

      // console.log('PROTECTIVE CLASSING Rg')

      // console.log('class ', 'Rg')
      console.log('protective class default')
      commit('setusertermlifeclass', 'Rg')
      commit('setLoading', false)
    }
  },

  // Symetra API Calls
  async symetraLoadFollowUps ({ commit }) {
    commit('setLoading', true)
    // get from the db cache
    let followupref = await firebase.database().ref('surveys').child('termlife').child('followupquestions').once('value')
    let followup = followupref.val()
    console.log('our follow ups are ', followup)
    let SYMETRA_QUESTIONS_KEY = 'LA2_AVOCATION'
    let questions = Object.values(followup[SYMETRA_QUESTIONS_KEY])
    console.log('questions', questions)
    let folloUpRec = {
      symetra_question_key: SYMETRA_QUESTIONS_KEY,
      followupQuestions: questions
    }
    commit('setSymetraFollowUps', folloUpRec)
    // set in a mutation
    // write the getter!
    // add a store value to hold it!
    commit('setLoading', false)
  },

  async symetraCreateTermLife ({ commit, state, dispatch }, payload) {
    commit('setLoading', true)
    let lifeId = payload.lifeId
    let quotekey = payload.quotekey
    let firstname = payload.firstname
    let lastname = payload.lastname
    let caseId = payload.caseId

    let rec = {
      quotekey: payload.quotekey,
      userkey: payload.userkey,
      lifeId: lifeId,
      caseId: caseId,
      firstname: firstname,
      lastname: lastname
    }
    console.log('posting to adapter ')
    let prodpath = state.symetraAPIPath + '/apply/createlife'
    await axios
      .post(prodpath,
        rec, {
          headers: {},
        })
      .then((response) => {
        console.log('symetra adapter response ', response.status, response.data)
        let lifeid = response.data.lifeid
        let quotekey = response.data.quotekey
        let userkey = response.data.userkey
        let rec = {
          userkey: firebase.auth().currentUser.uid,
          quotekey: quotekey
        }
        // reload the quote
        dispatch('loadquote', rec)
        commit('setLoading', false)
      })
      .catch((error) => {
        console.log(error)
        commit('setLoading', false)
      })
  },
  async symetraCreateLifeId ({ commit, state, dispatch }, payload) {
    commit('setLoading', true)
    console.log('changing loading state life id', true)
    let lifeId = payload.lifeId
    let quotekey = payload.quotekey
    let rec = {
      quotekey: payload.quotekey,
      userkey: payload.userkey,
      lifeId: lifeId
    }
    console.log('posting to adapter ')
    let prodpath = state.symetraAPIPath + '/apply/requestlifeid'
    await axios
      .post(prodpath,
        rec, {
          headers: {},
        })
      .then((response) => {
        console.log('symetra adapter response ', response.status, response.data)
        let lifeid = response.data.lifeid
        let quotekey = response.data.quotekey
        let userkey = response.data.userkey
        console.log(state.user)
        let rec = {
          userkey: firebase.auth().currentUser.uid,
          quotekey: quotekey
        }
        commit('setLoading', false)
        console.log('changing loading state life id return ', false)

        // reload the quote
        // dispatch('loadquote', rec)
      })
      .catch((error) => {
        console.log(error)
        commit('setLoading', false)
        console.log('changing loading state life id error ', false)
      })
  },
  async symetraCreateCaseId ({ commit, state, dispatch }, payload) {
    commit('setLoading', true)

    let lifeId = payload.lifeId
    let quotekey = payload.quotekey
    console.log('quote key sent to create case ', quotekey)
    let rec = {
      quotekey: payload.quotekey,
      userkey: payload.userkey,
    }
    console.log('posting to adapter ')
    let prodpath = state.symetraAPIPath + '/apply/createcase'
    await axios
      .post(prodpath,
        rec, {
          headers: {},
        })
      .then((response) => {
        console.log('symetra adapter response ', response.status, response.data)
        let quotekey = response.data.quotekey
        console.log('quote key returned from adapter ', quotekey)
        let userkey = response.data.userkey
        console.log(state.user)
        let rec = {
          userkey: firebase.auth().currentUser.uid,
          quotekey: quotekey
        }
        console.log(rec)
        // reload the quote
        dispatch('loadquote', rec)
        commit('setLoading', false)
      })
      .catch((error) => {
        console.log(error)
        commit('setLoading', false)
      })
  },

  async getTermLifeAnicoPriceDb ({ commit, state }, payload) {
    commit('setLoading', true)
    let smoker = 'N'
    if (payload.smoker === true) {
      smoker = 'Y'
    }

    let userclass = payload.userclass
    let gender
    if (payload.gender === 'M' || payload.gender === 'Male') {
      gender = 'Male'
    } else {
      gender = 'Female'
    }

    let clientage = payload.age
    if (!userclass || !clientage || !gender) {
      console.log('RETURNING NO DATA ', userclass, clientage, gender)
      return
    }
    // age: '35',
    // birthdate: '1980-10-01',
    // gender: 'Male',
    // userclass: 'P+',
    // smoker: nic,
    // termlength: '10',
    // faceamount: 250000
    // rates anico termlife Male 35 N P+ 10 250000
    console.log('rates for ', gender, ' client age  ', clientage, ' smoking ', smoker, ' userclass ', userclass)
    let ratesref = await firebase.database().ref('rates').child('anico').child('termlife').child(gender).child(clientage).child(smoker).child(userclass).once('value')
    let rates = ratesref.val()
    console.log('we have rates ', rates)
    let uifriendlyrates = []
    if (!rates) {
      console.log('NO RATES FOUND FOR ', payload)
    }
    // gives back all the term ranges, face values, rates per
    let termlength = Object.keys(rates)
    for (let term of termlength) {
      let facevalues = Object.keys(rates[term])
      for (let value of facevalues) {
        let rate = rates[term][value]
        let val = parseInt(value)
        let amount = 0
        amount = val
        let faceamount = amount
        let annualCost = rate.rateAnnual
        let monthlyPrem = rate.rateMonth
        console.log(' rate month ', rate.rateMonth)
        let biAnnualPrem = annualCost / 2
        let rec = {
          term: term,
          amount: faceamount,
          annualCost: numeral(annualCost).format('$0.00'),
          monthlyPrem: numeral(monthlyPrem).format('$0.00'),
          biAnnualPrem: numeral(biAnnualPrem).format('$0.00')
        }
        if (faceamount > 0) {
          uifriendlyrates.push(rec)
        }
        // calc bi-annual
        // calc monthly
        // add to record
      }
    }

    commit('setTermlifeRates', uifriendlyrates)
    commit('setLoading', false)
    // this.displayPrice = { monthlyPrem: numeral(monthlyPrem).format('$0.00'), biAnnualPrem: numeral(biAnnualPrem).format('$0.00') }
  },
  async calculateTermLifeAnicoPrice ({ commit, state }, payload) {
    commit('setLoading', true)
    let policyclassification = payload.policyclassification
    let gender = payload.gender
    let clientage = payload.age
    if (!policyclassification || !clientage || !gender) {
      return
    }
    let ratesref = await firebase.database().ref('rates').child('anico').child('termlife').child(gender).child(clientage).child(policyclassification).once('value')
    let rates = ratesref.val()
    let uifriendlyrates = []
    if (!rates) {
      console.log('NO RATES FOUND FOR ', payload)
    }
    let monthlyModal = 0.0875
    let semiannualModal = 0.515
    // gives back all the term ranges, face values, rates per
    let termlength = Object.keys(rates)
    for (let term of termlength) {
      let facevalues = Object.keys(rates[term])
      for (let value of facevalues) {
        let rate = rates[term][value].rate
        let val = parseInt(value)
        let amount = 0
        if (val === 499000) {
          // this is 250k to 499k
          amount = 250000
        }
        if (val === 749000) {
          // this is 250k to 499k
          amount = 500000
        }
        if (val === 999999) {
          // this is 250k to 499k
          amount = 750000
        }
        if (val === 1499999) {
          // this is 250k to 499k
          amount = 1000000
        }
        if (val === 2000000) {
          // this is 250k to 499k
          amount = 2000000
        }
        let faceamount = amount
        let currentUnits = amount / 1000
        let basePremium = rate * currentUnits // rate * units
        let symetrapoicyFee = 65
        let annualCost = symetrapoicyFee + basePremium
        let monthlyPrem = annualCost * monthlyModal
        let biAnnualPrem = annualCost * semiannualModal
        let rec = {
          term: term,
          amount: faceamount,
          annualCost: numeral(annualCost).format('$0.00'),
          monthlyPrem: numeral(monthlyPrem).format('$0.00'),
          biAnnualPrem: numeral(biAnnualPrem).format('$0.00')
        }
        if (faceamount > 0) {
          uifriendlyrates.push(rec)
        }
        // calc bi-annual
        // calc monthly
        // add to record
      }
    }

    commit('setTermlifeRates', uifriendlyrates)
    commit('setLoading', false)
    // this.displayPrice = { monthlyPrem: numeral(monthlyPrem).format('$0.00'), biAnnualPrem: numeral(biAnnualPrem).format('$0.00') }
  },
  async compareAnicoPrice ({ commit, state }, payload) {
    let uifriendlyrates = []
    let policyclassification = payload.policyclassification
    let gender = payload.gender
    let clientage = payload.age
    let dob = payload.birthday
    commit('setLoading', true)
    let prodpath = `http://${process.env.VUE_APP_FIREBASE_CLOUD_FUNCTION_URL}:${process.env.ANICO_PORT}/compare/anico`
    let rec = {
      birthdate: dob,
      userclass: policyclassification,
      gender: gender,
      faceamount: 250000,
      smoker: false,
      termlength: '10'
    }
    await axios
      .post(prodpath,
        rec, {
          headers: {},
        })
      .then((response) => {
        // console.log('!!!!!!!!@@!@!@!@ COMPARE ANICO API adapter response ', response.status, response.data)
        commit('setCompareList', response.data.data)
        commit('setLoading', false)
      })
      .catch((error) => {
        console.log(error)
        commit('setLoading', false)
      })
  },
  async calculateTermLifeSymetraPrice ({ commit, state }, payload) {
    commit('setLoading', true)
    let policyclassification = payload.policyclassification
    console.log('policy class inc ', payload.policyclassification)
    let gender = payload.gender
    let clientage = payload.age
    // policyclassification = 'Super Pref'
    // clientage = '46'
    // CHECK SMOKER Y/N
    console.log('payload to symetra prices is ', payload)
    if (!policyclassification || !clientage || !gender) {
      console.log('NOT ENOUGH DATA RETURNING SYMETRA PRICES', policyclassification, clientage, gender)
      return
    }
    if (policyclassification === 'P+' && payload.smoker === false) {
      policyclassification = 'Super Pref'
    }
    if (policyclassification === 'Super Pref' && payload.smoker === true) {
      policyclassification = 'Pref Nic'
    }
    if (policyclassification === 'P+' && payload.smoker === true) {
      policyclassification = 'Pref Nic'
    }
    if (policyclassification === 'Pf' && payload.smoker === true) {
      policyclassification = 'Pref Nic'
    }
    console.log('policy symetra is class out  ', payload.policyclassification, ' smoking ', payload.smoker)

    // calc nearest age!
    let birthdate = payload.birthdate
    let year = moment(birthdate).format('YYYY')
    let month = moment(birthdate).format('MM')
    let day = moment(birthdate).format('DD')
    let nearestAge = clientage
    let currentyear = moment().format('YYYY')
    let eventdate = moment(currentyear + '-' + month + '-' + day).add(6, 'months')
    let todaysdate = moment()
    console.log('event date ', eventdate.format('YYYY-MM-DD'), ' days to next bday ')
    if (todaysdate.isAfter(eventdate)) {
      console.log('advance their age a year')
      nearestAge = clientage + 1
      console.log('adding one to age so nearest age is ', nearestAge)
    }

    console.log('nearest age is ', nearestAge)
    // check for symetra policy classification
    let symetraclass = policyclassification
    if (policyclassification === 'Rg') {
      symetraclass = 'Standard Nic'
    }
    console.log('############# client age ', clientage, ' gender ', gender, ' policy class ', policyclassification, ' nearest age ', nearestAge)
    let ratesref = await firebase.database().ref('rates').child('symetra2021').child('termlife').child(gender).child(nearestAge).child(symetraclass).once('value')
    let rates = ratesref.val()

    let uifriendlyrates = []
    if (!rates) {
      console.log('NO RATES FOUND FOR ', payload)
    }
    // let monthlyModal = 0.0875
    // .0850 change april 2022
    // let semiannualModal = 0.515
    // .510 change april 2022
    let monthlyModal = 0.0850
    // .0850 change april 2022
    let semiannualModal = 0.510
    // .510 change april 2022
    // gives back all the term ranges, face values, rates per
    let termlength = Object.keys(rates)
    for (let term of termlength) {
      let facevalues = Object.keys(rates[term])
      for (let value of facevalues) {
        let rate = rates[term][value].rate
        let val = parseInt(value)
        let amount = 0
        if (val === 499000) {
          // this is 250k to 499k
          amount = 250000
        }
        if (val === 749000) {
          // this is 499k to 750k
          amount = 500000
        }
        if (val === 999999) {
          // this is 750k to 1000k
          amount = 750000
        }
        if (val === 1499999) {
          // this is 1000k to 1499k
          amount = 1000000
        }
        if (val === 2000000) {
          // this is to 2000k
          amount = 2000000
        }
        let faceamount = amount
        let currentUnits = amount / 1000
        let basePremium = rate * currentUnits // rate * units
        let symetrapoicyFee = 65
        let annualCost = symetrapoicyFee + basePremium
        let monthlyPrem = annualCost * monthlyModal
        let biAnnualPrem = annualCost * semiannualModal
        let rec = {
          term: term,
          amount: faceamount,
          annualCost: numeral(annualCost).format('$0.00'),
          monthlyPrem: numeral(monthlyPrem).format('$0.00'),
          biAnnualPrem: numeral(biAnnualPrem).format('$0.00')
        }
        if (faceamount > 0) {
          uifriendlyrates.push(rec)
        }
        // calc bi-annual
        // calc monthly
        // add to record
      }
    }

    // find our rate
    // loop through
    // find 10yr term 14.26
    // 20yr 20.74
    // 30yr 33.26
    console.log('!!!!!!!!! Looking for our rate')

    uifriendlyrates.forEach(entry => {
      if (entry.monthlyPrem === 14.26) {
        console.log('!!!!!!!!! we found our rate', entry)
      }
    })
    commit('setTermlifeRates', uifriendlyrates)
    commit('setLoading', false)
    // this.displayPrice = { monthlyPrem: numeral(monthlyPrem).format('$0.00'), biAnnualPrem: numeral(biAnnualPrem).format('$0.00') }
  },

  async symetraWOPRates ({ commit }, payload) {
    let faceamount = payload.faceamount
    let nearestage = payload.nearestage
    let termlength = payload.termlength
    // get the rates
    console.log(' nearest age ', nearestage, ' face amt ', faceamount)
    let ratesref = await firebase.database().ref('rates').child('symetra2022').child('riders').child('wop').child(nearestage).child(termlength).once('value')
    let rate = ratesref.val()
    // rate x face amt per 1k
    let price = 0
    price = ((parseFloat(faceamount) / 1000) * parseFloat(rate))
    console.log('wop first calc ', price)
    const monthModal = 0.0850
    let rateRec = {
      faceamount: faceamount,
      annualprice: numeral(price).format('$0.00'),
      monthlyprice: numeral(price * monthModal).format('$0.00')
    }
    commit('setWOPRates', rateRec)

    console.log('wop price ', rateRec, ' rate ', rate, ' term ', termlength, ' age ', nearestage, ' face amt ', parseFloat(faceamount))
  },
  async symetraADBRates ({ commit }, payload) {
    let faceamount = payload.faceamount
    let nearestage = payload.nearestage
    let gender = payload.gender
    commit('setADBRates', '')

    // get the rates
    console.log('!!! ADB', payload)
    console.log(' gender ', gender, ' face amt ', faceamount)
    let ratesref = await firebase.database().ref('rates').child('symetra2022').child('riders').child('adb').child(nearestage).child(gender).once('value')
    let rate = ratesref.val()
    // rate x face amt per 1k
    let price = 0
    price = ((parseFloat(faceamount) / 1000) * parseFloat(rate))
    console.log('wop first calc ', price)
    const monthModal = 0.0850
    let rateRec = {
      faceamount: faceamount,
      annualprice: numeral(price).format('$0.00'),
      monthlyprice: numeral(price * monthModal).format('$0.00')
    }
    commit('setADBRates', rateRec)
    console.log('adb price ', rateRec, ' rate ', rate, ' age ', nearestage, ' gender ', gender, ' face amt ', parseFloat(faceamount))
  },
  async symetraICBRates ({ commit }, payload) {
    // take in an array of values
    let faceamounts = payload
    console.log('faces passed ', faceamounts)
    const MAX_ICB = 10000
    let ratesref = await firebase.database().ref('rates').child('symetra2022').child('riders').child('icb').once('value')
    let rate = ratesref.val()

    let rateRecs = []
    const monthModal = 0.0850
    faceamounts.forEach(entry => {
      if (parseInt(entry.faceamount) > MAX_ICB) {
        rateRecs.push({
          faceamount: entry.faceamount,
          annualprice: 'Over Max Allowed',
          monthprice: 'Over Max Allowed'
        })
      } else {
        let price = 0
        price = (parseFloat(entry.faceamount) / 1000) * parseFloat(rate.rate)
        rateRecs.push({
          faceamount: entry.faceamount,
          annualprice: numeral(price).format('$0.00'),
          monthprice: numeral(price * monthModal).format('$0.00')
        })
      }
    })
    // get the rates

    // rate x face amt per 1k
    commit('setICBRates', rateRecs)

    console.log('icb pricing ', rateRecs)
  },
  async symetraCreatePolicy ({ commit, state, dispatch }, payload) {
    commit('setLoading', true)
    let lifeId = payload.lifeId
    let quotekey = payload.quotekey
    console.log('quote key sent to create policy ', payload)
    let rec = {
      quotekey: payload.quotekey,
      userkey: payload.userkey,
      lifeId: payload.lifeId,
      caseId: payload.caseId
    }
    console.log('posting to adapter ')
    let prodpath = state.symetraAPIPath + '/apply/createpolicy'
    await axios
      .post(prodpath,
        rec, {
          headers: {},
        })
      .then((response) => {
        console.log('symetra adapter response ', response.status, response.data)
        let quotekey = response.data.quotekey
        console.log('quote key returned from adapter ', quotekey)
        let userkey = response.data.userkey
        let policyid = response.data.policyid
        console.log(state.user)
        let rec = {
          userkey: firebase.auth().currentUser.uid,
          quotekey: quotekey
        }
        console.log(rec)
        // reload the quote
        dispatch('loadquote', rec)
        commit('setLoading', false)
      })
      .catch((error) => {
        console.log(error)
        commit('setLoading', false)
      })
  },
  // RECORD USER POLICIES IN THEIR PROFILE AS THEY FILL OUT INTERVIEWS
  updateTermlifeQuote ({ commit, state }, payload) {
    commit('setLoading', true)
    let quotekey
    const policy = {
      policytype: 'Termlife',
      termlength: payload.termlength,
      policyvalue: payload.policyvalue,
      provider: payload.provider,
      interview: payload.interview,
      timestamp: Date.now()
    }

    if (payload.quotekey) {
      quotekey = payload.quotekey
    }

    if (quotekey) {
      // update record
      // this needs to be quotes/termlife/{provider}/{keyval}/data
      firebase.database().ref('users').child(firebase.auth().currentUser.uid).child('policies').child('termlife').child(quotekey).update(policy)
        .catch(
          (error) => {
            console.log(error)
          }
        )
    } else {
      // new record
      firebase.database().ref('users').child(firebase.auth().currentUser.uid).child('policies').child('termlife').push(policy)
        .catch(
          (error) => {
            console.log(error)
          }
        )
    }
    commit('setLoading', false)
  },

  async processTestQuestion ({ commit, state }, payload) {
    const rec = {
      something: 'test'
    }
    console.log('posting to adapter ')
    let prodpath = 'http://localhost:3000/symetraTestLoop'
    commit('setLoading', true)
    await axios
      .post(prodpath,
        rec, {
          headers: {
            // 'Content-Type': 'application/json',
            // 'API-key': '992394823984728934792378',
            // 'API-Username': 'alphauser',
            // 'API-Password': 'batman'
          },
        })
      .then((response) => {
        console.log('symetra tester adapter response ', response, response.data)
        commit('setfollowup', response.data)
        commit('setLoading', false)
      })
      .catch((error) => {
        console.log(error)
        commit('setLoading', false)
      })
  },
  createGenericBlanketPartner ({ commit }, payload) {
    commit('setLoading', true)
    console.log('partner data ', payload)
    const rec = {
      toEmails: payload.emails,
      template_data: {
        partner_url: payload.partnerUrl
      }
    }

    const genericpartnerEmail = firebase.functions().httpsCallable('genericpartnerEmail')
    return genericpartnerEmail(rec)
      .then(data => {
        const resp = data.data.response.data
        console.log('partnerEmail response: ', data)
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        commit('setLoading', false)
      })
  },
  partnerEmail ({ commit }, payload) {
    commit('setLoading', true)
    const rec = {
      toEmails: payload.emails,
      template_data: {
        partner_url: payload.partnerUrl
      }
    }

    const partnerEmail = firebase.functions().httpsCallable('partnerEmail')
    return partnerEmail(rec)
      .then(data => {
        const resp = data.data.response.data
        console.log('partnerEmail response: ', resp)
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        commit('setLoading', false)
      })
  },
  async getCaTermVideo () {
    const storage = firebase.storage()
    const storageRef = storage.ref('video/caterm/Leah.mp4')
    let itemData = await storageRef.getDownloadURL()
    return itemData
  },
  async getReport ({ commit }, payload) {
    commit('setLoading', true)
    const rec = {
      startDate: payload.startDate,
      endDate: payload.endDate,
      type: payload.type
    }
    try {
      const func = firebase.functions().httpsCallable('getReport')
      const response = await func(rec)
      if (response?.haserror) {
        return commit('setError', 'No data found for selected date')
      }
      const csvContent = response?.data?.response
      downloadCSV(csvContent, payload.type)
      commit('setError', 'File downloaded successfully')
    } catch (e) {
      console.log(e)
      commit('setError', 'No data found for selected date')
    } finally {
      commit('setLoading', false)
    }
  }
}
