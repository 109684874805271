<template>
  <div class="sec4-dsk">
  <v-row class="pt-16">
    <v-col class="sec4-dsk-title">
      {{ $t("dashboard.section5.h1") }}
    </v-col>
  </v-row>
  <v-row class="mt-0">
    <v-col class="sec4-dsk-sub">
      {{ $t("dashboard.section5.copy") }}
    </v-col>
  </v-row>
  <div
  class="review-carousel"
  @touchstart="handleTouchStart"
  @touchmove="handleTouchMove"
  @touchend="handleTouchEnd"
  >
    <div class="carousel">
      <div
      v-for="(review, index) in reviews"
      :key="index"
      class="carousel-item"
      :class="{ active: index === currentIndex }"
      >
        <div class="rating">
          <span
          v-for="star in 5"
          :key="star"
          class="mdi"
          :class="{ 'mdi-star': star <= review.rating, 'mdi-star-outline': star > review.rating }"
          >
          </span>
        </div>
        <p class="sec4-qt">{{ review.body }}</p>
        <p class="sec4-qt-name font-weight-bold text-center">{{ review.name }}</p>
      </div>
    </div>
  </div>
   <div class="dots">
      <span
      v-for="(review, index) in reviews"
      :key="index"
      class="dot"
      :class="{ active: index === currentIndex }"
      @click="goToReview(index)"
      >
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      reviews: {
        type: Array,
        required: true
      },
      currentIndex: {
        type: Number,
        required: true
      },
      autoPlay: {
        type: Boolean,
        default: true
      },
      intervalDuration: {
        type: Number,
        default: 5000
      }
    },
    mounted () {
      if (this.autoPlay) {
        this.startAutoPlay()
      }
    },
    methods: {
      startAutoPlay () {
        this.intervalId = setInterval(this.nextSlide, this.intervalDuration)
      },
      stopAutoPlay () {
        clearInterval(this.intervalId)
      },
      nextSlide () {
        this.currentIndex = (this.currentIndex + 1) % this.reviews.length
      },
      goToReview (index) {
        this.currentIndex = index
      },
      handleTouchStart (event) {
        this.startX = event.touches[0].clientX
        this.startY = event.touches[0].clientY
      },
      handleTouchMove (event) {
        const touch = event.touches[0]
        const deltaX = touch.clientX - this.startX
        const deltaY = touch.clientY - this.startY
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
          event.preventDefault()
        }
      },
      handleTouchEnd (event) {
        const touch = event.changedTouches[0]
        const deltaX = touch.clientX - this.startX
        const threshold = 50
        if (deltaX > threshold) {
          this.nextSlide()
        } else if (deltaX < -threshold) {
          this.prevSlide()
        }
      },
    },
    beforeDestroy () {
      this.stopAutoPlay()
    }
  }
</script>

<style>
.sec4-dsk {
  height: 635px;
  background: #ffffff;
}
.sec4-dsk-title {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 110%;
  /* or 46px */
  text-align: center;
  letter-spacing: -0.424019px;
  /* Dark Green */
  color: #005f6c;
}
.sec4-dsk-sub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.424019px;
  color: #292f36;
}
.sec4-icon {
  font-size: 36px !important;
}
.sec4-qt {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  font-size: 21px;
  line-height: 140%;
  /* or 29px */
  text-align: center;
  /* Color Palette/Dark Grey */
  color: #292f36;
  width: 700px;
  margin: auto;
}
.sec4-qt-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 140%;
  color: #292f36;
}
.carousel {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.carousel-item {
  flex: 0 0 auto;
  display: none;
}

.carousel-item.active {
  display: block;
}

.rating {
  color: #00a1b7;
  margin-top: 32px;
}

.mdi {
  font-size: 32px;
}

.review-carousel{
  text-align: center;
}
.dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: #333;
}
@media screen and (min-width: 300px) and (max-width:500px) {
  .sec4-dsk{
    height: auto;
    margin-bottom: 50px;
  }
  .sec4-qt{
    max-width: 100%;
    padding-right: 8px;
    padding-left: 8px;
  }
}
</style>
