// var numeral = require('numeral')
import { updateField } from 'vuex-map-fields'
export default {
  updateField,

  SET_BAR_IMAGE (state, payload) {
    state.barImage = payload
  },
  SET_DRAWER (state, payload) {
    state.drawer = payload
  },
  SET_SCRIM (state, payload) {
    state.barColor = payload
  },
  set_tickets (state, payload) {
    state.tickets = [...payload]
  },
  setloginError (state, payload) {
    state.loginerror = payload
  },
  setError (state, payload) {
    state.error = payload
  },
  setPurchaseError (state, payload) {
    state.purchaseerror = payload
  },
  setcurrentuserkey (state, payload) {
    state.currentUserKey = payload
  },
  setbmi (state, payload) {
    state.bmi = payload
  },
  setTermlifeRates (state, payoad) {
    state.termLifeRates = payoad
  },
  setCompareList (state, payload) {
    state.compareList = payload
  },
  setSymetraFollowUps (state, payload) {
    state.symetrafollowups = payload
  },
  setusertermlifeclass (state, payload) {
    state.usertermlifeclass = payload
  },
  setnic (state, payload) {
    state.nic = payload
  },
  setgender (state, payload) {
    state.gender = payload
  },
  setage (state, payload) {
   state.age = payload
  },
  setincome (state, payload) {
    state.income = payload
  },
  setLoadedQuote (state, payload) {
    state.quote = payload
  },
  setsafestatus (state, payload) {
    state.safes = payload
  },
  setPostcode (state, payload) {
    state.postcodedata = payload
  },
  setLoadedPolicies (state, payload) {
    state.userpolicies = payload
  },
  setMobileStatus (state, payload) {
    state.mobilestatus = payload
  },
  setEmailStatus (state, payload) {
    state.emailstatus = payload
  },
  setProviderRecommendations (state, payload) {
    state.providerrecommendations = payload
  },
  setTravelDetails (state, payload) {
    state.travelDetails = payload
  },
  setInsuredPersons (state, payload) {
    state.insuredPersons = payload
  },
  setTravelPolicyPurchase (state, payload) {
    state.canadaTravelPurchase = payload
  },
  setTravelCoverageDetails (state, payload) {
    state.travelCoverageDetails = payload
  },
  setUSATravelPolicies (state, payload) {
    state.usatravelpolicies = payload
  },
  setUSATravelDetails (state, payload) {
    state.USATravelDetails = payload
  },
  setCanadianTravelPolicies (state, payload) {
    state.travelpolicies = payload
  },
  setPartnerId (state, payload) {
    state.partnerId = payload
  },
  setLoadedBlanketPartners (state, payload) {
    state.blanketpartners = payload
  },
  setDataSpyApplicants (state, payload) {
    state.DataSpyApplicants = payload
  },
  setLoadedPartnerCampaigns (state, payload) {
    state.partnerCampaigns = payload
  },
  setCampaignId (state, payload) {
    state.campaignId = payload
  },
  setEligibility (state, payload) {
    state.eligibility = payload
  },
  setEligibilityData (state, payload) {
    state.eligibilityData = payload
  },
  setEligibilityDetails (state, payload) {
    state.eligibilityDetails = payload
  },
  setPetBreeds (state, payload) {
    state.petbreeds = [...payload]
  },
  setPetNotEligible (state, payload) {
    state.petNotEligible = payload
  },
  setPetAges (state, payload) {
    state.petages = [...payload]
  },
  setTrupanionPolicies (state, payload) {
    state.petPolicies = payload
  },
  setPetDetails (state, payload) {
    state.petDetails = payload
  },
  setPetCoverageDetails (state, payload) {
    state.petCoverageDetails = payload
  },
  setPetQuoteResponse (state, payload) {
    state.petQuoteResponse = payload
  },
  setPetPremiums (state, payload) {
    if (payload.idx === undefined) {
      state.petPrems.push(payload.premiums)
    } else {
      state.petPrems[payload.idx] = payload.premiums
    }
  },
  resetPets (state, payload) {
    state.pets = []
  },
  addPets (state, payload) {
    state.pets.push(payload)
  },
  removePet (state, payload) {
    state.pets.splice(payload, 1)
  },
  updatePet (state, payload) {
    // find pet based on id
    let pet = state.pets.find(pet => pet.id === payload.id)
    // replace pet with updated pet
    if (pet) {
      pet = payload
    }
  },
  updatePetPolicyOptions (state, payload) {
    let pet = state.pets[payload.idx]
    pet.PolicyOptions = payload.policyOptions
    pet.Deductible = payload.deductible
  },
  updatePetAge (state, payload) {
    // find pet based on id
    let pet = state.pets.find(pet => pet.id === payload.id)
    // replace pet with updated pet
    if (pet) {
      pet.DateOfBirth = payload
    }
  },
  setPetBillingPreview (state, payload) {
    state.petBillingPreview = payload
  },
  setPetDeductible (state, payload) {
    let pet = state.pets[payload.index]
    pet.PolicyOptions.Deductible = payload.deductible
  },
  setCanadianTravelProducts (state, payload) {
    state.canadaTravelProducts = payload
  },
  setTravelCanadaQuoteFinalPricing (state, payload) {
    state.canadaQuoteFinalPricing = payload
  },
  setTravelCanadaQuote (state, payload) {
    state.canadaTravelQuote = payload
  },
  setTravelCanadaSurveys (state, payload) {
    state.canadaTravelSurveys = payload
  },
  setTravelCanadaError (state, payload) {
    state.canadianTravelError = payload
  },
  setTravelUSAQuote (state, payload) {
    state.usaTravelQuote = payload
  },
  setUSATravelCostPrem (state, payload) {
    state.usaTravelCostPrem = payload
  },
  setUSATravelCostPref (state, payload) {
    state.usaTravelCostPref = payload
  },
  setUSATravelCostStd (state, payload) {
    state.usaTravelCostStd = payload
  },
  setCFARCost (state, payload) {
    state.CFARCost = state.usaCancellationCost - state.usaTravelCostPrem
  },
  setRentalDamagePricePref (state, payload) {
    state.rentalDamagePricePref = state.usaRentalPricePref - state.usaTravelCostPref
  },
  setRentalDamagePriceStd (state, payload) {
    state.rentalDamagePriceStd = state.usaRentalPriceStd - state.usaTravelCostStd
  },
  setUSARentalPricePref (state, payload) {
    state.usaRentalPricePref = payload
  },
  setUSARentalPriceStd (state, payload) {
    state.usaRentalPriceStd = payload
  },
  setUSACancellationCost (state, payload) {
    state.usaCancellationCost = payload
  },
  setTravelUSAPolicy (state, payload) {
    state.travelUSAPolicy = payload
  },
  setPetPolicy (state, payload) {
    state.petPolicy = payload
  },
  clearError (state) {
    state.error = null
  },
  clearLoginResponse (state) {
    state.loginerror = null
  },
  clearLoginError (state) {
    state.loginerror = null
  },
  setSystemEvents (state, payload) {
    state.systemevents = payload
  },

  setUserProfile (state, payload) {
    state.userprofile = payload
  },
  applieddetails (state, payload) {
    state.applieddetails = payload
  },
  setLoadedProfiles (state, payload) {
    state.siteprofiles = payload
  },
  setfollowup (state, payload) {
    state.followup = payload
  },
  setUser (state, payload) {
    state.user = payload
  },
  setLoggedInUser (state, payload) {
    console.log({ payload })
    state.loggedInUser = payload
  },
  setLoadedUserProfile (state, payload) {
    state.userprofile = payload
  },

  setLoadedAlerts (state, payload) {
    state.alerts = payload
  },

  setLoadedTermLifeQuestions (state, payload) {
    state.termquestions = payload
  },
  setSymetraPdfs (state, payload) {
    state.symetraPdfFiles = payload
  },
  setSymetraICC20LA1 (state, payload) {
    state.symetraICC20LA1 = payload
  },
  setSymetraICC20LA2 (state, payload) {
    state.symetraICC20LA2 = payload
  },
  setSymetraICC20LA3 (state, payload) {
    state.symetraICC20LA3 = payload
  },
  setSymetraLU820 (state, payload) {
    state.symetraLU820 = payload
  },
  setLoadedSymetraOccupations (state, payload) {
    state.symetraOccupations = payload
  },
  setLoadedSymetraAppQuestions (state, payload) {
    state.symetraAppQuestions = payload
  },
  setLoadedAnicoAppQuestions (state, payload) {
    state.anicoAppQuestions = payload
  },
  setDefaultAnicoTermLifeInterview (state, payload) {
    let questions = payload
    questions.forEach(entry => {
      Object.assign(state.anicoTermlifeInterview, { [entry.label]: '' })
    })
  },
  setnoticesloading (state, payload) {
    state.pdfloading = payload
  },
  setRenderedApplicationsLoading (state, payload) {
    state.renderedApplicationsLoading = payload
  },
  setCompletedClientloading (state, payload) {
    state.completedPdfLoading = payload
  },
  setSymetraCCompletedClientPdfs (state, payload) {
    state.symetraCompletedClientPdfs = payload
  },
  setCompletedSymetraPDFApplication (state, payload) {
    state.completedSymetraPDFApplication = payload
  },
  setReadyforSignature (state, payload) {
    state.readyforSignature = payload
  },
  setTrupanionPaymentParams (state, payload) {
    state.trupanionPaymentParams = payload
  },
  setPetInfoData (state, payload) {
    state.petInfoData = payload
  },
  setPetPlanData (state, payload) {
    state.petPlanData = payload
  },
  setWOPRates (state, payload) {
    state.woprates = payload
  },
  setADBRates (state, payload) {
    state.adbrates = payload
  },
  setICBRates (state, payload) {
    state.icbrates = payload
  },
  setLoading (state, payload) {
    state.isloading = payload
  },
  setApplicationID (state, payload) {
    state.applicationID = payload
  },
  setShowApplicationNotification (state, payload) {
    state.showApplicationNotification = payload
  },
  setLoadedCanadianTravelPolicies (state, payload) {
    state.CanadianTravelPolicies = payload
  },
  setDisplayname (state, payload) {
    state.userdisplay = payload
  },
  setDisplaybio (state, payload) {
    state.userbio = payload
  },
  setDisplaylevel (state, payload) {
    state.userlevel = payload
  },
  setDisplaypoints (state, payload) {
    state.userpoints = payload
  },
  setAutoDetails (state, payload) {
    state.travelDetails = payload
  },
  setTugoMedicalSurvey (state, payload) {
    state.tugoMedicalSurvey = payload
  },
  setWebsiteLocation (state, payload) {
    state.websiteLocation = payload
  },
  setUserIp (state, payload) {
    state.userIp = payload
  },
  setLanguage (state, payload) {
    state.lang = payload
  },
  setPrefferedLang (state, payload) {
    state.prefferedLang = payload
  },
  setPageLoaded (state, payload) {
     state.pageLoaded = payload
  },
  setIsPolicyPurchased (state, payload) {
    state.isPolicyPurchased = payload
  }
}
