<template>
  <router-view />
</template>

<script>
  export default {
    name: 'App',
    watch: {
      $route (to) {
        document.title = to.meta.title
        let existingCanonical = document.querySelector('link[rel="canonical"]')
        if (existingCanonical) {
          existingCanonical.remove()
        }
        if (to.meta.canonical) {
          let link = document.createElement('link')
          link.setAttribute('rel', 'canonical')
          link.setAttribute('href', to.meta.canonical)
          document.head.appendChild(link)
        }
      }
    },
    mounted () {
      this.$watch('$route', this.updateMeta)
      this.updateMeta()
    },
    methods: {
      updateMeta () {
        const to = this.$route
        document.title = to.meta.title || 'Blanket.com'
        let existingCanonical = document.querySelector('link[rel="canonical"]')
        if (existingCanonical) {
          existingCanonical.remove()
        }
        if (to.meta.canonical) {
          let link = document.createElement('link')
          link.setAttribute('rel', 'canonical')
          link.setAttribute('href', to.meta.canonical)
          document.head.appendChild(link)
        }
      }
    }
  }
</script>
<style>
  /* Prevent horizontal scrolling for the entire body */
  html,body{
    overflow-x: hidden;
  }
.v-overlay.v-overlay--active.theme--dark{
.v-overlay__scrim{
opacity: 0.6!important;
}
}
  /* Hide scrollbar for small screen sizes */
  @media (max-width: 768px) {
    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* Remove underline decoration from all link v-btn components */
  a.v-btn {
    text-decoration: none;
  }
</style>
